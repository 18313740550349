import { StorageService } from './../../../shared/services/storage.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppSettings } from '../../../shared/app.settings';
import { Message } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { TranslateService } from '@ngx-translate/core';
import { ManageEmployeeService } from './../../../corporate-manage-employee/services/manage-employee.service';
import { EmployeeUserDetailsModel } from './../../../corporate-manage-employee/models/employee-user-details';
import { EmployeeModel } from './../../../corporate-manage-employee/models/employee';

@Component({
  selector: 'app-editable-employee',
  templateUrl: './editable-employee.component.html'
})
export class EditableEmployeeComponent implements OnInit, OnChanges {
  message: Message[] = [];
  @Output() isEmpPopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isEmpPopSave: EventEmitter<any> = new EventEmitter();
  @Input() editEmployeeInfo;
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  corpId;
  @Input() empAPIError = [{
    empId: false,
    department: false,
    name: false,
    contactNo: false,
    emailId: false,
    VIPPassenger: false,
    numCountryCode: false,
    general: false
  }];
  employeeId: any = null;
  editEmployee = new UntypedFormGroup({
    empId: new UntypedFormControl(),
    department: new UntypedFormControl(),
    name: new UntypedFormControl(),
    contactNo: new UntypedFormControl(),
    email: new UntypedFormControl(),
    VIPPassenger: new UntypedFormControl(),
    countryCode: new UntypedFormControl()
  });
  labelSave;
  labelCancel;
  chooseLabel;
  deptOptions: any[] = [];
  uploadedImage = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private translate: TranslateService,
    private manageEmployeeService: ManageEmployeeService,
    private storageService: StorageService
  ) { }

  ngOnChanges() {
    this.editEmployee.reset();
    this.empAPIError = [{
      empId: false,
      department: false,
      name: false,
      contactNo: false,
      emailId: false,
      VIPPassenger: false,
      numCountryCode: false,
      general: false
    }];
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.chooseLabel = this.commonBindingDataService.getLabel('label_choose');
    this.editEmployee.reset();
    if (this.editEmployeeInfo !== null && this.editEmployeeInfo !== undefined) {
      this.editEmployee.controls.name.setValue(this.editEmployeeInfo.employeeNameStr);
      this.editEmployee.controls.empId.setValue(this.editEmployeeInfo.employeeId);
      this.editEmployee.controls.contactNo.setValue(this.editEmployeeInfo.phoneNum);
      this.editEmployee.controls.email.setValue(this.editEmployeeInfo.email);
      this.editEmployee.controls.VIPPassenger.setValue(this.editEmployeeInfo.vip);
      this.editEmployee.controls.department.setValue(this.editEmployeeInfo.departmentId);
      this.employeeId = this.editEmployeeInfo.id;
      if (this.editEmployeeInfo.numCountryCode === undefined
        || this.editEmployeeInfo.numCountryCode === null
        || this.editEmployeeInfo.numCountryCode === '') {
        const error = {
          numCountryCode: [
            { message: this.commonBindingDataService.getLabel('country_code_required') }
          ]
        };
        this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.empAPIError);
      } else {
        this.editEmployee.controls.countryCode.setValue(this.editEmployeeInfo.numCountryCode);
      }
      if (this.editEmployeeInfo.profile !== undefined && this.editEmployeeInfo.profile !== null && this.editEmployeeInfo.profile !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + this.editEmployeeInfo.profile;
        this.uploadedImage = this.editEmployeeInfo.profile;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
        this.uploadedImage = '';
      }

    } else {
      this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      this.uploadedImage = '';
    }
  }
  ngOnInit() {
    this.corpId = this.storageService.getItem(AppSettings.CURRENT_USER_CORPORATE_ID);
    this.editEmployee = this.formBuilder.group({
      empId: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
      department: ['', [Validators.required]],
      contactNo: ['',
        [
          Validators.required,
          Validators.pattern(AppSettings.PHONE_PATTERN),
          Validators.minLength(6),
          Validators.maxLength(17),
        ]],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      name: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
      VIPPassenger: [false],
      countryCode: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern(AppSettings.PHONE_CODE)
      ]]
    });
    this.deptOptions.push({ label: 'Please select', value: '' });
    this.manageEmployeeService.departmentOption(this.corpId).subscribe(results => {
      results.corporates[0].departments.forEach(element => {
        this.deptOptions.push({ label: element.departmentName, value: element.departmentId });
      });
    }, (error) => {
      console.log(error);
    });

  }
  addEmployeeDetails() {
    const userDetials = new EmployeeUserDetailsModel({
      departmentId: this.editEmployee.controls.department.value,
      referrenceNumber: this.editEmployee.controls.empId.value,
      profileImage: this.uploadedImage,
      vip: this.editEmployee.controls.VIPPassenger.value
    });
    let lastName;
    if (this.editEmployee.controls.name.value.split(' ')[1] !== '') {
      lastName = this.editEmployee.controls.name.value.split(' ')[1];
    } else {
      lastName = '';
    }
    const data = new EmployeeModel({
      firstName: this.editEmployee.controls.name.value.split(' ')[0],
      email: this.editEmployee.controls.email.value,
      lastName: lastName,
      phoneNum: this.editEmployee.controls.contactNo.value,
      userDetails: userDetials,
      numCountryCode: this.editEmployee.controls.countryCode.value,
    });
    this.manageEmployeeService.addORUpdate(data, this.corpId, this.employeeId).subscribe(results => {
      this.editEmployee.reset();
      this.empAPIError = [{
        empId: false,
        department: false,
        name: false,
        contactNo: false,
        emailId: false,
        VIPPassenger: false,
        numCountryCode: false,
        general: false
      }];
      this.isEmpPopSave.emit(results);
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.empAPIError);
    });
  }

  cancel() {
    this.editEmployee.reset();
    this.empAPIError = [{
      empId: false,
      department: false,
      name: false,
      contactNo: false,
      emailId: false,
      VIPPassenger: false,
      numCountryCode: false,
      general: false
    }];
    this.isEmpPopCancel.emit(false);
  }

  onUploadProfile(event) {
    this.profileUrl = AppSettings.GET_FILE_THUMB_URL + event.fileId;
    this.uploadedImage = event.fileId;

  }

  dispalyMessage(msgObj, severity) {
    this.message = [];
    msgObj.general.forEach(element => {
      this.message.push({
        severity: severity,
        summary: severity.charAt(0).toUpperCase() + severity.slice(1),
        detail: element.message
      });
    });
  }
}


