import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-stats-counts',
  templateUrl: './dashboard-stats-counts.component.html'
})
export class DashboardStatsCountsComponent implements OnInit {

  @Input() statsAndCount;

  constructor() { }

  ngOnInit() { }

}
