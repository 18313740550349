import { PassengerInfoModel } from './passenger-info-model';

export class RideShareModel {
  tenantId = null;
  estimateId = null;
  vehicleTypeId = null;
  serviceTypeId = null;
  pickupLocationName = null;
  sourceLatitude = null;
  sourceLongitude = null;
  dropLocationName = null;
  destinationLatitude = null;
  destinationLongitude = null;
  paymentType = null;
  passengerType = null;
  passengerId = null;
  driverId = null;
  rideShareNumOfPassenger = null;
  passengerDetails = null;
  tripNote = null;
  pEmail = null;
  pNumCountryCode = null;
  pPhoneNum = null;
  pFirstName = null;
  pLastName = null;
  stripeCardId = null;
  pNetId = null;

  constructor(option?: {
    tenantId: string;
    estimateId: string;
    vehicleTypeId: string;
    serviceTypeId: string;
    pickupLocationName: string;
    sourceLatitude: number;
    sourceLongitude: number;
    dropLocationName: string;
    destinationLatitude: number;
    destinationLongitude: number;
    paymentType: number;
    passengerType: string;
    passengerId: string;
    driverId: string;
    rideShareNumOfPassenger: number;
    passengerDetails: PassengerInfoModel;
    tripNote: string;
    pEmail: string;
    pNumCountryCode: string;
    pPhoneNum: string;
    pFirstName: string;
    pLastName: string;
    stripeCardId: string;
    pNetId?: string;
  }) {
    if (option) {
      this.tenantId = option.tenantId;
      this.estimateId = option.estimateId;
      this.vehicleTypeId = option.vehicleTypeId;
      this.serviceTypeId = option.serviceTypeId;
      this.pickupLocationName = option.pickupLocationName;
      this.sourceLatitude = option.sourceLatitude;
      this.sourceLongitude = option.sourceLongitude;
      this.dropLocationName = option.dropLocationName;
      this.destinationLatitude = option.destinationLatitude;
      this.destinationLongitude = option.destinationLongitude;
      this.paymentType = option.paymentType;
      this.passengerType = option.passengerType;
      this.passengerId = option.passengerId;
      this.driverId = option.driverId;
      this.rideShareNumOfPassenger = option.rideShareNumOfPassenger;
      this.passengerDetails = option.passengerDetails;
      this.tripNote = option.tripNote;
      this.pEmail = option.pEmail;
      this.pNumCountryCode = option.pNumCountryCode;
      this.pPhoneNum = option.pPhoneNum;
      this.pFirstName = option.pFirstName;
      this.pLastName = option.pLastName;
      this.stripeCardId = option.stripeCardId;
      this.pNetId = option.pNetId;
    }
  }
}

