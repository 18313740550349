import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { StorageService } from 'app/modules/shared/services/storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  @Output() onSignInEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onForgotPasswordEmitter: EventEmitter<any> = new EventEmitter();

  signInForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.usernamePlaceholder = this.commonBindingDataService.getLabel('Username');
    this.passwordPlaceHolder = this.commonBindingDataService.getLabel('Password');
    this.signInLabel = this.commonBindingDataService.getLabel('label_sign_in');
    this.signInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
    });
  }

  doSignIn() {
    this.onSignInEmitter.emit(this.getLoginObject());
  }

  doSignInWithSSO() {
    //this.onSignInEmitter.emit(this.getLoginObjectForSSO());
    // this.commonBindingDataService.checkLoginUser.next('SSOUser');
    this.storageService.setSessionStorage('loggedInUserType', 'ssoUser');

    this.getLoginObjectForSSO();
  }

  getLoginObjectForSSO() {
    window.location.href = `${AppSettings.BASE_URL}/${AppSettings.TENANT}/saml2/ssologin?appType=admin`;
  }


  getLoginObject() {
    let signInData = {};
    if (this.signInForm.controls.username.errors === null) {
      signInData = {
        'email': (this.signInForm.controls.username.value).trim(),
        'password': this.signInForm.controls.password.value,
        'tenantId': AppSettings.TENANT
      };
    } else {
      signInData = {
        'username': (this.signInForm.controls.username.value).trim(),
        'password': this.signInForm.controls.password.value,
        'tenantId': AppSettings.TENANT
      };
    }
    return signInData;
  }

  onForgotPassword() {
    this.onForgotPasswordEmitter.emit();
  }

  eventHandler(event) {
    if (event.keyCode === AppSettings.ENTER_KEY) {
      this.onSignInEmitter.emit(this.getLoginObject());
    }
  }

}
