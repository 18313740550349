import { Injectable } from '@angular/core';

import { RestApiService } from '../services/rest-api.service';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSettings } from '../app.settings';
import { HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
declare var Stripe: any;
@Injectable()
export class UserCommonService {
  isMetadataLoaded = false;
  isCompanyRole = false;
  stripElements: any;
  stripe: any;
  currentLatitude;
  currentLongitude;
  public locationFound = new Subject<any>();

  constructor(private restApiService: RestApiService,
    private storageService: StorageService) {
    if (this.storageService.getItem(AppSettings.TOKEN_KEY)) {
      this.getStripeKeyData();
    }
    this.getAdminUserMetaData();
    // this.getCustomerBookingMetaData();
  }
  signOut(): Observable<any> {
    return this.restApiService.delete('/secure/signout', 'page-center');
  }

  signIn(data): Observable<any> {
    return this.restApiService.post('/user-signin', data, 'page-center');
  }

  ssoSignIn(data): Observable<any> {
    const adminHeader = new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'x-app-type': 'admin'
    });
    return this.restApiService.post('/saml2/authcreation', data, 'page-center', {headers: adminHeader});
  }

  passwordChange(data): Observable<any> {
    return this.restApiService.put('/secure/users/profile/password', data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('/secure/users/profile', 'page-center');
  }

  updateUserProfile(data): Observable<any> {
    return this.restApiService.put('/secure/users/profile', data, 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('/users/forgot-password', data, 'page-center');
  }

  resendOtp(param, data): Observable<any> {
    return this.restApiService.put('/users/resend-otp?' + param, data, 'page-center');
  }

  requestOtp(data): Observable<any> {
    return this.restApiService.post('/users/signup-otp/request', data, 'page-center');
  }

  validateOtp(data): Observable<any> {
    return this.restApiService.post('/users/verify-otp', data, 'page-center');
  }

  getServiceAreaId(): Observable<any> {
    return this.restApiService.get('/secure/tenants/servicearea', 'page-center');
  }

  getUserMetaData(): Observable<any> {
    return this.restApiService.get('/secure/user-metadata', 'page-center');
  }

  getUserMetaDataInsecure(): Observable<any> {
    return this.restApiService.get('/admin/metadata', 'page-center');
  }

  getTrackMyLocationDetails(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/' + bookingId, 'page-center');
  }

  getTrackMyLiveLocation(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/live/' + bookingId);
  }

  getAdminUserMetaData() {
    this.getUserMetaDataInsecure().subscribe(result => {
      this.storageService.setItem(AppSettings.TENANT_CONFIG, result.tenantConfigKeys);
      // result.googleBrowserAPIKey
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      // script.src = `https://maps.googleapis.com/maps/api/js?key=${tempAel
      //   }&libraries=visualization,places`;

      script.src = `https://maps.googleapis.com/maps/api/js?key=${result.googleBrowserAPIKey
        }&libraries=visualization,places`;
      document.getElementsByTagName('head')[0].appendChild(script);
      setTimeout(() => {
        this.isMetadataLoaded = true;
        this.getCurrentLocation();
      }, 2000);
    }, (error) => {
    });
  }

  // getCurrentLocation(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resp => {
  //       console.log("Latitude1: " + resp.coords.longitude +
  //           "Longitude1: " + resp.coords.latitude);
  //       resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
  //     },
  //       err => {
  //         reject(err);
  //       });
  //   });
  // }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // console.log(position);
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
        this.locationFound.next(0);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  getStripeKeyData() {
    // this.getUserMetaData().subscribe(result => {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.async = true;
    //   script.src = `https://js.stripe.com/v3/`;
    //   this.stripe = Stripe(`${result.pkKey}`); // use your test publishable key
    //   this.stripElements = this.stripe.elements();
    //   document.getElementsByTagName('head')[0].appendChild(script);

    // }, (error) => {
    // });
  }

  // specially for booking by customer start
  getPreBookingDetails(): Observable<any> {
    return this.restApiService.get(`/web-booking/pre-booking/data`, 'page-center');
  }

  getpackages(vehicleTypeId, sLatitude, sLongitude): Observable<any> {
    return this.restApiService.get(`/secure/package/list?vehicleTypeId=${vehicleTypeId}` +
      `&sLatitude=${sLatitude}&sLongitude=${sLongitude}`, 'page-center');
  }

  getPassengersList(searchString?): Observable<any> {
    return this.restApiService.get('/web-booking/passenger-list?searchString=' + searchString);
  }

  getPassengersListByNetID(netID?): Observable<any> {
    return this.restApiService.get('/web-booking/passenger-list-by-netid?netId=' + netID);
  }

  getFareEstimate(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/web-booking/fare-estimation/${servicetypeid}`, data, 'page-center');
  }

  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/web-booking/booking/${servicetypeid}`, data, 'page-center');
  }

  bookRideNowAndRideLaterRental(data): Observable<any> {
    return this.restApiService.post(`/secure/rental/booking/ADMIN`, data, 'page-center');
  }

  bookRideNowAndRideLaterOccasional(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/occasional-ride-booking/manual/${servicetypeid}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/booking/manual`,
      data, 'page-center');
  }

  getDriversList(srcLat, srcLng, destLat, DestLng, numOfSeats, rideTypeId, vehicleTypeId,
    searchString?, pickUpDateRideLater?, serviceTypeId?): Observable<any> {
    return this.restApiService.get('/web-booking/driver-list?rideTypeId=' + rideTypeId + '&pickupLat=' + srcLat +
      '&pickupLong=' + srcLng + '&destLat=' + destLat + '&destLong=' + DestLng + '&numOfSeats=' +
      numOfSeats + '&vehicleTypeId=' + vehicleTypeId + '&searchString=' + searchString + '&pickupDateTime='
      + pickUpDateRideLater + '&limit=9999&offset=0' + '&serviceTypeId=' + serviceTypeId);
  }

  getNearByVehicles(sLatitude, sLongitude, dLatitude, dLongitude, radius, serviceTypeId, vehicleTypeId): Observable<any> {
    return this.restApiService.get(`/secure/vehicles/data/nearbyvehicles/eta?sLatitude=${sLatitude}&sLongitude=${sLongitude}` +
      `&dLatitude=${dLatitude}&dLongitude=${dLongitude}&radius=${radius}&serviceTypeId=${serviceTypeId}` +
      `&vehicleTypeId=${vehicleTypeId}`, 'page-center');
  }

  getNearByVehiclesForRideShare(sLatitude, sLongitude, dLatitude, dLongitude, serviceTypeId): Observable<any> {
    return this.restApiService.get(`/secure/ride-share/near-by-vehicles/eta?slat=${sLatitude}&slong=${sLongitude}&dlat=${dLatitude}` +
      `&dlong=${dLongitude}&serviceTypeId=${serviceTypeId}`, 'page-center');
  }

  getFareEstimateForRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/fare-estimate`, data, 'page-center');
  }

  getVehicleTypeByServiceId(serviceId?): Observable<any> {
    return this.restApiService.get('/web-booking/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
    // return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
  }

  getWorkOrderNumber(workOrderId): Observable<any> {
    return this.restApiService.get(`/tour/workordervalidation/${workOrderId}`, 'page-center');
  }
  // specially for booking by customer End
}