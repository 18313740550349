import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
// import { BookingFaresComponent } from 'app/modules/bookings/components/booking-fares/booking-fares.component';
import { CityFaresModel } from "app/modules/bookings/models/fare-estimate-model";
// import {  } from 'app/modules/bookings/services/booking.service';
import { AppSettings } from "app/modules/shared/app.settings";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ErrorBindingService } from "app/modules/shared/services/error-binding.service";
import { StorageService } from "app/modules/shared/services/storage.service";
import { UserCommonService } from "app/modules/shared/services/user-common.service";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { RideShareModel } from "app/modules/bookings/models/ride-share-model";
import { Subscription } from "rxjs";
import { DestModel } from "app/modules/bookings/models/dest-model";
declare const google: any;

@Component({
  selector: "app-booking-by-customer",
  templateUrl: "./booking-by-customer.component.html",
  styleUrls: ["./booking-by-customer.component.scss"],
})
export class BookingByCustomerComponent implements OnInit, OnDestroy {
  minBookingTime = 0;
  maxBookingTime = 0;
  manualBookingData: any = {};
  selectedTimeInMillis = 0;
  startOfSelectedDate = 0;
  dateSelected: Boolean = false;
  timeNotInRangeError = "";
  dataForFareEstimate = new CityFaresModel();
  rideShareFares = [];
  dialogLabel = this.commonBindingDataService.getLabel(
    "label_booking_confirmation"
  );
  showBookingFareDialog = false;
  bookingFareModel;
  noOfSeats = "";
  noOfSeatsList = [];
  selectedPassenger: any;
  payLabel;
  userid: any;
  cardDetails: any = {};
  passengerOptions = [];
  fName = false;
  lName = false;
  isCorporatePassenger = this.commonBindingDataService.getTenantConfigValue(
    AppSettings.IS_MANAGE_CORPORATES
  );
  // isEmailOptional = this.commonBindingDataService.getTenantConfigValue(AppSettings.EMAIL_OPTIONAL);
  isDestinationOptional = this.commonBindingDataService.getTenantConfigValue(
    AppSettings.DESTINATION_OPTIONAL
  );
  loaderDisplay = false;
  countryCodeList = AppSettings.COUNTRY_CODE;
  displayDropStar =
    this.isDestinationOptional && this.isDestinationOptional === "true"
      ? true
      : false;
  displaySaveButton: Boolean = false;
  marker;

  // @ViewChild(BookingFaresComponent, { static: false }) childView: BookingFaresComponent;

  source: any = DestModel;
  manualBookingErrors = [
    {
      estimateId: false,
      vehicleTypeId: false,
      serviceTypeId: false,
      pickupLocationName: false,
      sourceLatitude: false,
      sourceLongitude: false,
      firstName: false,
      lastName: false,
      dropLocationName: false,
      destinationLatitude: false,
      destinationLongitude: false,
      promoCode: false,
      rideType: false,
      paymentType: false,
      passengerType: false,
      driverType: false,
      pickupDateTime: false,
      // rideLaterPickupTime: false,
      packageId: false,
      serviceFrom: false,
      serviceTo: false,
      workOrder: false,
    },
  ];

  rideTypeValue: string = null;
  serviceTypeValue = false;

  minDate = new Date();
  maxDate = new Date();
  currentDate = new Date(new Date().setHours(0, 0, 0, 0));

  bookingMap: any;
  directionsService: any = new google.maps.DirectionsService();
  directionsDisplay: any = new google.maps.DirectionsRenderer();
  markers: any = [];
  DEFAULT_RADIOUS = 3000;
  carTypeOptions: Array<any> = [];
  rideTypeOptions: Array<any> = [];
  rideTypeOptionsForRental: Array<any> = [];
  rideTypes: Array<any> = [];
  preebookingData: any;
  showVehicleType = false;
  showRideType = true;

  paymentTypeOptions: Array<any> = [];
  serviceTypeOptions: any = [];
  message: Message[] = [];
  nearbyCarsList = [];
  // passengerEmailOptional;
  serviceTypeId = AppSettings.TAXI;
  origin = null;
  destination = null;
  vehicleTypeId = null;
  isAppUsersPassengerType = "";
  isManualDriverType = "";
  passengerDataSet: any[] = [];
  paymentTypeOptions1: any[] = [];
  driverDataSet: any[] = [];
  selectedPassengerIndex = -1;
  selectedDriverIndex = -1;
  tripNotes: any = false;
  tenantInfo: any;
  // emailResults: any[];
  phoneResults: any[];
  netIDResults: any[];
  // emailList = [];
  phoneList = [];
  netIDList = [];
  packageOptions: Array<any> = [];
  options = {
    center: {
      lat: AppSettings.DEFAULT_LATITUDE,
      lng: AppSettings.DEFAULT_LONGITIDE,
    },
    zoom: 6,
    zoomControl: true,
    mapTypeId: google.maps.MapTypeId.ROADMAPs,
  };

  defaultProfileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  profileUrl = AppSettings.GET_FILE_THUMB_URL;
  manualBookingForm: UntypedFormGroup;
  newPassengerForm: UntypedFormGroup;
  searchPassengerForm: UntypedFormGroup;
  searchDriverForm: UntypedFormGroup;

  passengerTypeOptions: Array<any> = [];
  driverTypeOptions: Array<any> = AppSettings.DRIVER_TYPES_MANUAL_BOOKING;
  disableVehicleType = false;
  paymentTypes: any[];

  creditPaymentTypes: any[];
  dateFormat;
  disbleServiceType = false;

  vehicleTypeOptions: any = [];
  isOccasionalTrip = false;
  isSameCitiesError = false;
  cityOptions: any[] = [];
  serviceFromOptions: any[] = [];
  serviceToOptions: any[] = [];
  configCalled = false;
  defaultCountryCode;
  subscribedVariable: Subscription;
  cleartimer;

  addRouteStopForm = new UntypedFormArray([]);
  passengerArrayForm = new UntypedFormArray([]);
  waypts = [];
  passengerCount = 1;
  validWorkOrder;
  invalidWorkOrder;
  validWorkOrderFlag: Boolean = false;
  invalidWorkOrderFlag: Boolean = false;
  stopClose: Boolean = true;
  // rideLaterPickupTime: Date;
  passengerForm = [
    {
      passenger: new UntypedFormControl(),
    },
  ];
  bannerText = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    // private bookingService: BookingService,
    private commonBindingDataService: CommonBindingDataService,
    private router: Router,
    private errorBindingService: ErrorBindingService,
    private storageService: StorageService,
    private confirmationService: ConfirmationService,
    private commonService: UserCommonService,
    private messageService: MessageService
  ) {
    // window.scrollTo(0,0);
  }

  // passengerOptions = [{
  //   label: 'Please Select',
  //   value: ''
  // },
  // {
  //   label: 'Individual Passenger',
  //   value: false
  // },
  // {
  //   label: 'Corporate Passenger',
  //   value: true
  // },];

  ngOnInit() {
    this.formValidation();
    this.afterConfigCall();
    this.addRow();
    this.subscribedVariable = this.commonService.locationFound.subscribe(
      (result) => {
        this.showPosition();
      }
    );
    this.getServiceAvailability();
  }

  getServiceAvailability() {
    this.commonBindingDataService.getServiceAvailability().subscribe((results) => {
        console.log('results...........!',results);
        if(results.serviceUnavailabilityDataList.length) {
          this.bannerText = results.serviceUnavailabilityDataList[0].message;
        } else {
          this.bannerText = '';
        }
      },
      (error) => {
        this.showMessage("Error", error.general[0].message);
      }
    );
  }

  afterConfigCall() {
    this.dateFormat = this.commonBindingDataService.getDateFormat();
    this.getPassengerType();
    this.paymentTypes = this.commonBindingDataService
      .getTenantConfigValue(AppSettings.PAYMENT_TYPES)
      .split(",");
    this.tripNotes = this.commonBindingDataService.getTenantConfigValue(
      AppSettings.TRIP_NOTE
    );
    this.defaultCountryCode = this.commonBindingDataService.getTenantConfigValue(
      AppSettings.NUM_COUNTRY_CODE
    );
    this.minDate = new Date(new Date().setHours(0, 0, 0, 0));
    this.tenantInfo = this.storageService.getItem(AppSettings.TENANT_INFO);
    // if (this.tenantInfo !== undefined && this.tenantInfo !== null) {
    //   this.options.center.lat = this.tenantInfo.latitude;
    //   this.options.center.lng = this.tenantInfo.longitude;
    // }

    // this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);
    this.carTypeOptions.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });
    // this.packageOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
    this.rideTypeOptions.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });
    this.rideTypeOptionsForRental.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });

    this.paymentTypeOptions.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });
    this.rideTypes = this.rideTypeOptions;
    // this.setDropDown(this.paymentTypeOptions, this.paymentTypes, 'name', 'value');
    // this.paymentTypeOptions = this.paymentTypes.map(element => ({ label: element, value: element }));
    this.paymentTypeOptions1 = this.paymentTypes.map((element) => ({
      label: element,
      value: element,
    }));

    this.passengerTypeOptions.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });

    this.formValidation();

    // this.newPassengerForm = this.formBuilder.group({
    //   firstName: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(AppSettings.PERSON_NAME_PATTERN)]],
    //   lastName: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(AppSettings.PERSON_NAME_PATTERN)]],
    //   email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    //   numCountryCode: ['', [Validators.required, Validators.pattern(AppSettings.PHONE_CODE)]],
    //   phoneNum: ['',
    //     [
    //       Validators.required,
    //       Validators.minLength(7),
    //       Validators.maxLength(17),
    //       Validators.pattern(AppSettings.PHONE_PATTERN)
    //     ]
    //   ]
    // });

    this.searchPassengerForm = this.formBuilder.group({
      searchPassenger: ["", []],
    });

    this.searchDriverForm = this.formBuilder.group({
      searchDriver: ["", []],
    });

    this.setPreBookingDetails();
    // this.getPassengersList('');
    this.setNoOfSeats();
    if (this.isCorporatePassenger === "true") {
      this.manualBookingForm.controls.passengerTypes.setValidators([]);
    }

    if (this.serviceTypeOptions.length === 2) {
      this.serviceTypeOptions.splice(0, 1);
      this.disbleServiceType = true;
    } else {
      this.serviceTypeOptions = this.storageService.getItem(
        AppSettings.SERVICE_TYPE
      );
      this.manualBookingForm.controls.dropLocationName.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.dropLocationName.updateValueAndValidity();
    }

    // const emailOptional = this.isEmailOptional.split(',');
    // for (let i = 0; i < emailOptional.length; i++) {
    //   if (emailOptional[i] === 'PASSENGER') {
    //     this.passengerEmailOptional = emailOptional[i];
    //     this.manualBookingForm.controls.email.setValidators([]);
    //   }
    // }
    // this.getServiceArea();
  }

  getPassengerType() {
    this.passengerOptions.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });

    // this.commonService.getUserMetaData().subscribe(results => {
    //   for (let i = 0; i < results.passengerTypeList.length; i++) {
    //     this.passengerOptions.push({ label: results.passengerTypeList[i].roleName, value: results.passengerTypeList[i].id });
    //   }
    // }, (error) => {
    //   this.showMessage('Error', error.general[0].message);
    // });
  }

  formValidation() {
    this.manualBookingForm = this.formBuilder.group({
      tenantId: [""],
      estimateId: ["", []],
      serviceTypeId: ["", []],
      vehicleTypeId: ["", [Validators.required]],
      pickupLocationName: ["", [Validators.required]],
      sourceLatitude: ["", [Validators.required]],
      sourceLongitude: ["", [Validators.required]],
      dropLocationName: ["", [Validators.required]],
      destinationLatitude: ["", [Validators.required]],
      destinationLongitude: ["", [Validators.required]],

      packageId: ["", []],
      netId: ['', [Validators.required]],
      // email: ['', [Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      phone: [
        "",
        [
          Validators.pattern(AppSettings.PHONE_PATTERN),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      phoneCode: [this.defaultCountryCode],
      // passengerName: ['', [Validators.required]],
      firstName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(AppSettings.NAME_PATTERN),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(AppSettings.NAME_PATTERN),
        ],
      ],
      promoCode: [""],
      passenger: this.passengerArrayForm,
      rideType: ["", [Validators.required]],
      paymentType: ["CASH", [Validators.required]],
      driverType: [
        AppSettings.DRIVER_TYPES_MANUAL_BOOKING[2].value,
        [Validators.required],
      ],
      passengerType: ["", []],
      passengerTypes: ["", []],
      pickupDateTime: [""],
      noOfSeats: [""],
      note: ["", [Validators.maxLength(150)]],
      // rideLaterPickupTime: [{ value: "", disabled: !this.dateSelected }],
      serviceFrom: ["", []],
      serviceTo: ["", []],
      workOrder: ["", [Validators.required]],
      stops: this.addRouteStopForm,
    });
  }

  // onFocusEmail(email) {
  //   const selectedPhoneData = this.emailList.find(element => {
  //     return element.email === email;
  //   });
  //   if (selectedPhoneData) {
  //     this.setEmailDetails(selectedPhoneData.email);
  //   }

  // }

  // setEmailDetails(event) {
  //   // const selectedPhoneNumber = this.emailList.find(element => {
  //   //   return element.email.toLowerCase() === event.value.toLowerCase();
  //   // });
  //   const selectedPhoneNumber = this.emailList.find(element => {
  //     return element.email === event;
  //   });
  //   if (selectedPhoneNumber.userid) {
  //     this.userid = selectedPhoneNumber.userid;
  //   }
  //   if (selectedPhoneNumber) {
  //     this.manualBookingForm.controls.phone.setValue(selectedPhoneNumber.phoneNum);
  //     this.manualBookingForm.controls.phoneCode.setValue(selectedPhoneNumber.numCountryCode);
  //     this.manualBookingForm.controls.firstName.setValue(selectedPhoneNumber.firstName);
  //     this.manualBookingForm.controls.lastName.setValue(selectedPhoneNumber.lastName);
  //     this.manualBookingForm.controls.firstName.disable();
  //     this.manualBookingForm.controls.lastName.disable();
  //     this.manualBookingForm.controls.phoneCode.disable();
  //     this.manualBookingForm.controls.phone.disable();
  //   }
  // }

  setNetIDDetails(event) {
    const selectedEmailAndName = this.netIDList.find((element) => {
      // return element.id === event.value;
      return element.netId === event;
    });
    if (selectedEmailAndName.userid) {
      this.userid = selectedEmailAndName.userid;
    }

    if (selectedEmailAndName) {
      // this.manualBookingForm.controls.email.setValue(selectedEmailAndName.email);
      this.manualBookingForm.controls.phoneCode.setValue(
        selectedEmailAndName.numCountryCode
      );
      this.manualBookingForm.controls.firstName.setValue(
        selectedEmailAndName.firstName
      );
      this.manualBookingForm.controls.lastName.setValue(
        selectedEmailAndName.lastName
      );
      this.manualBookingForm.controls.phone.setValue(
        selectedEmailAndName.phoneNum
      );
      this.passengerArrayForm.setValue([
        {
          userId: selectedEmailAndName.userid,
          firstName:
            selectedEmailAndName.firstName +
            " " +
            selectedEmailAndName.lastName,
          lastName: selectedEmailAndName.lastName,
          phoneNumCode: selectedEmailAndName.numCountryCode,
          phoneNum: selectedEmailAndName.phoneNum,
          email: selectedEmailAndName.email,
        },
      ]);
      this.passengerArrayForm.updateValueAndValidity();
      this.manualBookingForm.controls.firstName.disable();
      this.manualBookingForm.controls.lastName.disable();
      this.manualBookingForm.controls.phoneCode.disable();
    }
  }

  // setPhoneDetails(event) {
  //   const selectedEmailAndName = this.phoneList.find((element) => {
  //     // return element.id === event.value;
  //     return element.phoneNum === event;
  //   });
  //   if (selectedEmailAndName.userid) {
  //     this.userid = selectedEmailAndName.userid;
  //   }

  //   if (selectedEmailAndName) {
  //     // this.manualBookingForm.controls.email.setValue(selectedEmailAndName.email);
  //     this.manualBookingForm.controls.phoneCode.setValue(
  //       selectedEmailAndName.numCountryCode
  //     );
  //     this.manualBookingForm.controls.firstName.setValue(
  //       selectedEmailAndName.firstName
  //     );
  //     this.manualBookingForm.controls.lastName.setValue(
  //       selectedEmailAndName.lastName
  //     );
  //     this.passengerArrayForm.setValue([
  //       {
  //         userId: selectedEmailAndName.userid,
  //         firstName:
  //           selectedEmailAndName.firstName +
  //           " " +
  //           selectedEmailAndName.lastName,
  //         lastName: selectedEmailAndName.lastName,
  //         phoneNumCode: selectedEmailAndName.numCountryCode,
  //         phoneNum: selectedEmailAndName.phoneNum,
  //         email: selectedEmailAndName.email,
  //       },
  //     ]);
  //     this.passengerArrayForm.updateValueAndValidity();
  //     this.manualBookingForm.controls.firstName.disable();
  //     this.manualBookingForm.controls.lastName.disable();
  //     this.manualBookingForm.controls.phoneCode.disable();
  //     // this.manualBookingForm.controls.email.disable();
  //   }
  // }
  addRow() {
    // this.passengerForm.push({
    //   passenger: new FormControl(''),
    // });
    this.passengerArrayForm.push(
      this.formBuilder.group({
        userId: [""],
        firstName: ["", [Validators.required]],
        lastName: [""],
        phoneNumCode: [""],
        phoneNum: [""],
        email: [""],
      })
    );
    // this.passenger.push(this.formBuilder.control(''));
    // console.log(this.manualBookingForm.controls['passenger']['controls'][0].controls.firstName);
    if (this.passengerArrayForm.length > 1) {
      this.passengerCount = this.passengerArrayForm.length;
    } else if (this.passengerArrayForm.length == 1) {
      this.passengerCount = 1;
      // this.manualBookingForm.controls['passenger']['controls'][0].controls.firstName.disable();
    }
  }
  removePassenger(index: number) {
    if (index >= 1) {
      this.passengerArrayForm.removeAt(index);
    }
    if (this.passengerArrayForm.length > 1) {
      this.passengerCount = this.passengerArrayForm.length;
    } else if (this.passengerArrayForm.length == 1) {
      this.passengerCount = 1;
    } else if (this.passengerArrayForm.length == 0) {
      this.passengerCount = 0;
    }
  }
  addStop() {
    // this.stopClicked = true;
    this.addRouteStopForm.push(
      this.formBuilder.group({
        address: ["", [Validators.required]],
        latitude: [""],
        longitude: [""],
        locationId: [""],
      })
    );
    if (this.addRouteStopForm.length > 3) {
      this.stopClose = false;
    }
  }
  deleteStop(index) {
    if (this.addRouteStopForm.length < 5) {
      this.stopClose = true;
    }
    if (this.addRouteStopForm.length > 1) {
      let stops = (this.manualBookingForm.get("stops") as UntypedFormArray).controls;
      this.addRouteStopForm.controls.splice(index, 1);
    } else if (this.addRouteStopForm.length == 1) {
      let stops = (this.manualBookingForm.get("stops") as UntypedFormArray).controls;
      this.addRouteStopForm.controls.splice(index, 1);
    } else {
      let stops = (this.manualBookingForm.get("stops") as UntypedFormArray).controls;
      stops[index].reset();
    }
    this.addRouteStopForm.updateValueAndValidity();
    // this.getAllETA();
    // if(this.addRouteStopForm.length == 0){
    //   this.stopClose = true;
    // }
    if (
      this.source !== undefined &&
      this.source !== null &&
      this.destination !== undefined &&
      this.destination !== null
    ) {
      this.calculateAndDisplayRoute(
        this.directionsService,
        this.directionsDisplay
      );
    }
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    this.waypts = [];
    const stops = (this.manualBookingForm.get("stops") as UntypedFormArray).controls;
    for (let route = 0; route < stops.length; route++) {
      if (
        stops[route].get("latitude").value !== null &&
        stops[route].get("latitude").value !== "" &&
        stops[route].get("longitude").value !== "" &&
        stops[route].get("longitude").value !== null
      ) {
        this.waypts.push({
          location: new google.maps.LatLng(
            stops[route].get("latitude").value,
            stops[route].get("longitude").value
          ),
          stopover: true,
        });
      }
    }
    directionsService.route(
      {
        origin: {
          lat: this.source.latitude,
          lng: this.source.longitude,
        },
        destination: {
          lat: this.destination.latitude,
          lng: this.destination.longitude,
        },
        waypoints: this.waypts,
        optimizeWaypoints: true,
        travelMode: "DRIVING",
      },
      function (response, status) {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        } else {
          this.error = 0;
          this.onRouteDropPointsList = [];
        }
      }
    );
  }
  setStopLocation(location, index) {
    const stops = (this.manualBookingForm.get("stops") as UntypedFormArray).controls;
    const address = location.address.replace("`", "'");
    if (location.address !== null) {
      stops[index].get("address").setValue(address);
      stops[index].get("latitude").setValue(location.lat);
      stops[index].get("longitude").setValue(location.lng);
    }
  }
  workOrderDetails() {
    const workOrderId = this.manualBookingForm.value.workOrder;
    if (workOrderId) {
      this.commonService.getWorkOrderNumber(workOrderId).subscribe((result) => {
        if (result.message == "Valid work order id") {
          this.validWorkOrder = result.message;
          this.validWorkOrderFlag = true;
          this.invalidWorkOrderFlag = false;
        }
        if (result.message == "Invalid work order id.") {
          this.invalidWorkOrder = result.message;
          this.invalidWorkOrderFlag = true;
          this.validWorkOrderFlag = false;
        }
      });
    }
    else {
      this.manualBookingForm.controls['workOrder'].markAsTouched();
    }
  }
  workOrderValid(event) {
    this.validWorkOrderFlag = false;
  }
  setPreBookingDetails() {
    this.commonService.getPreBookingDetails().subscribe(
      (result) => {
        this.preebookingData = result;
        this.setDropDown(
          this.carTypeOptions,
          result.vehicleTypeList,
          "vehicleType",
          "id"
        );
        this.setDropDown(
          this.rideTypeOptions,
          result.rideTypeList,
          "name",
          "value"
        );
        // this.setDropDown(this.creditPaymentTypes, result.corporatePaymentTypeList, 'name', 'value');
        // this.creditPaymentTypes = result.corporatePaymentTypeList.map(element => ({ label: element.name, value: element.value }));
        // this.setDropDown(this.paymentTypeOptions, result.paymentTypeList, 'name', 'value');
        this.setDropDown(
          this.paymentTypeOptions,
          result.paymentTypeList,
          "name",
          "value"
        );
        // this.setDropDown(this.passengerTypeOptions, result.passengerTypeList, 'name', 'value');
        // this.serviceTypeOptions = this.storageService.getItem(AppSettings.SERVICE_TYPE);
        // this.creditPaymentTypes = result.corporatePaymentTypeList;
        // this.creditPaymentTypes.push({ label: result.corporatePaymentTypeList[0].name, value: result.corporatePaymentTypeList[0].value });

        // if (this.serviceTypeOptions.length === 2) {
        //   this.serviceTypeOptions.splice(0, 1);
        //   this.disbleServiceType = true;
        // } else {
        //   this.serviceTypeOptions = this.storageService.getItem(AppSettings.SERVICE_TYPE);
        // }

        // this.setDropDown(this.carTypeOptions, result.vehicleTypeList, 'vehicleType', 'id');
        // this.setDropDown(this.paymentTypeOptions, result.paymentTypeList, 'name', 'value');

        // if (this.serviceTypeOptions.length === 2) {
        //   this.serviceTypeOptions.splice(0, 1);
        //   this.disbleServiceType = true;
        // } else {
        //   this.serviceTypeOptions = this.storageService.getItem(AppSettings.SERVICE_TYPE);
        // }

        // if (this.serviceTypeOptions[2].value === 'tenantServiceTypes00000000000002') {
        //   // this.setDropDown(this.rideTypeOptions, result.rideTypeList, 'name', 'value');
        // } else {
        //   const newRideTypeOptions = result.rideTypeList.splice(0, 2);
        //   this.setDropDown(this.rideTypeOptions, newRideTypeOptions, 'name', 'value');
        // }

        this.manualBookingForm.controls.serviceTypeId.setValue(
          AppSettings.TAXI
        );
        this.getVehicleTypes(AppSettings.TAXI);
        // this.bookingMap.setCenter({ lat: result.defaultLatitude, lng: result.defaultLongitude });
        this.minBookingTime = result.minBookingTime;
        this.maxBookingTime = result.maxBookingTime;
        this.minDate = new Date(
          moment().utc().add(this.minBookingTime, "milliseconds").valueOf()
        );
        this.maxDate = new Date(
          moment().utc().add(this.maxBookingTime, "milliseconds").valueOf()
        );
        this.setDefaultValuesForFands();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setDefaultValuesForFands() {
    if (this.paymentTypeOptions && this.paymentTypeOptions.length > 0) {
      this.manualBookingForm.controls.paymentType.setValue(
        this.paymentTypeOptions[1].value
      );
    }

    // set rideType for F&S
    this.changeRideType("", this.preebookingData.rideTypeList[1].value); // 2 = ride later
    this.manualBookingForm.controls.rideType.setValue(
      this.preebookingData.rideTypeList[1].value
    );
    this.showRideType = false;

    this.changeVehicleType("", this.preebookingData.vehicleTypeList[0].id);
    this.manualBookingForm.controls.vehicleTypeId.setValue(
      this.preebookingData.vehicleTypeList[0].id
    );
    if (this.preebookingData.vehicleTypeList.length === 1) {
      this.showVehicleType = false;
      // this.manualBookingForm.controls.vehicleTypeId.setValue('');
    }
    if (this.preebookingData.vehicleTypeList.length > 1) {
      this.showVehicleType = true;
    }

    // this.manualBookingForm.controls.pickupDateTime.setValue(new Date(new Date().setHours(0, 0, 0, 0)));
    this.manualBookingForm.controls.pickupDateTime.setValue(new Date());
    this.startOfSelectedDate = moment(new Date()).startOf("day").valueOf();
    const setMinValidTime = new Date(
      moment(this.minDate).add(2, "m").valueOf()
    );
    // this.manualBookingForm.controls.rideLaterPickupTime.enable();
    // this.manualBookingForm.controls.rideLaterPickupTime.setValue("");

    this.updateMinMaxDateTime();
  }

  updateMinMaxDateTime() {
    this.minDate = new Date(
      moment().utc().add(this.minBookingTime, "milliseconds").valueOf()
    );
    this.maxDate = new Date(
      moment().utc().add(this.maxBookingTime, "milliseconds").valueOf()
    );
    this.cleartimer = setTimeout(() => {
      this.updateMinMaxDateTime();
    }, 30000);
  }

  getVehicleTypes(serviceId?) {
    this.commonService.getVehicleTypeByServiceId(serviceId).subscribe(
      (vehicleTypeData) => {
        this.carTypeOptions = vehicleTypeData.map((element) => ({
          label: element.vehicleType,
          value: element.id,
        }));
      },
      (error) => {
        this.showMessage("Error", error.general[0].message);
      }
    );
  }

  getPackages() {
    // if (!this.origin) {
    //   return;
    // }
    // this.commonService.getpackages(this.vehicleTypeId, this.origin.lat(), this.origin.lng()).subscribe(result => {
    //   this.packageOptions = result.activePackageList.map(element => ({ label: element.packageDisplayName, value: element.id }));
    // }, (error) => {
    //   this.showMessage('Error', error.general[0].message);
    // });
  }

  // emailSearch(event) {
  //   this.loaderDisplay = true;
  //   const query = event.query;
  //   this.resetPhonefeild();

  //   this.commonService.getPassengersList(query).subscribe(results => {
  //     this.loaderDisplay = false;
  //     if (results.length) {
  //       this.emailList = results;
  //       this.emailResults = this.filterEmail(query, this.emailList);
  //     } else {
  //       this.userid = '';
  //     }
  //   });

  //   setTimeout(() => {
  //     this.onFocusEmail(query);
  //   }, 2000);

  // }

  // phoneSearch(event) {
  //   this.manualBookingForm.controls.firstName.enable();
  //   this.manualBookingForm.controls.lastName.enable();
  //   this.manualBookingForm.controls.phoneCode.enable();
  //   const query = event.query;
  //   this.commonService.getPassengersList(query).subscribe((results) => {
  //     if (results.length) {
  //       this.phoneList = results;
  //       this.phoneResults = this.filterPhone(query, this.phoneList);
  //     } else {
  //       this.userid = "";
  //     }
  //   });
  // }

  netIdSearch(event) {
    this.manualBookingForm.controls.firstName.enable();
    this.manualBookingForm.controls.lastName.enable();
    this.manualBookingForm.controls.phoneCode.enable();
    const query = event.query;
    this.commonService.getPassengersListByNetID(query).subscribe((results) => {
      if (results.length) {
        this.netIDList = results;
        this.netIDResults = this.filterNetID(query, this.netIDList);
      } else {
        this.userid = "";
      }
    });
  }

  resetPhonefeild() {
    this.manualBookingForm.controls.phone.reset();
    this.manualBookingForm.controls.netId.reset();
    this.manualBookingForm.controls.phoneCode.reset();
    this.manualBookingForm.controls.firstName.reset();
    this.manualBookingForm.controls.lastName.reset();
    this.manualBookingForm.controls.firstName.enable();
    this.manualBookingForm.controls.lastName.enable();
    this.manualBookingForm.controls.phoneCode.enable();
    this.manualBookingForm.controls.phone.enable();
    this.manualBookingForm.controls.netId.enable();
  }

  // resetEmailfeild() {
  //   this.manualBookingForm.controls.email.reset();
  //   this.manualBookingForm.controls.phoneCode.reset();
  //   this.manualBookingForm.controls.firstName.reset();
  //   this.manualBookingForm.controls.lastName.reset();
  //   // this.manualBookingForm.controls.passengerName.reset();
  // }

  // filterEmail(query, emailList: any[]): any[] {
  //   const filtered: any[] = [];
  //   for (let i = 0; i < emailList.length; i++) {
  //     if (emailList[i].email.toLowerCase().indexOf(query.toLowerCase()) === 0) {
  //       filtered.push(emailList[i].email);
  //     }
  //   }
  //   return filtered;
  // }

  filterNetID(query, netIDList: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < netIDList.length; i++) {
      if (
        netIDList[i].netId.toLowerCase().indexOf(query.toLowerCase()) === 0
      ) {
        filtered.push(netIDList[i].netId);
      }
    }
    return filtered;
  }

  filterPhone(query, phoneList: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < phoneList.length; i++) {
      if (
        phoneList[i].phoneNum.toLowerCase().indexOf(query.toLowerCase()) === 0
      ) {
        filtered.push(phoneList[i].phoneNum);
      }
    }
    return filtered;
  }

  setDropDown(dropdownVar, dropdownData, labelKey, valueKey) {
    for (let i = 0; i < dropdownData.length; i++) {
      dropdownVar.push({
        label: dropdownData[i][labelKey],
        value: dropdownData[i][valueKey],
      });
    }
  }

  getFareEstimates() {
    for (const keys in this.dataForFareEstimate) {
      if (this.manualBookingForm.value[keys] !== undefined) {
        this.dataForFareEstimate[keys] = this.manualBookingForm.value[keys];
      }
    }

    if (this.rideTypeValue !== "3") {
      this.getFareEstmateForRideNowAndLater();
    } else {
      this.getFareEstmateForRideShare();
    }
  }

  getFareEstmateForRideNowAndLater() {
    // new Date(this.dataForFareEstimate.pickupDateTime);
    // new Date(this.dataForFareEstimate.pickupDateTime).setHours(0, 0, 0, 0)
    // let gmtDate: any = this.commonBindingDataService.toGMT(this.dataForFareEstimate.pickupDateTime);
    // this.dataForFareEstimate.pickupDateTime = gmtDate;
    if (this.manualBookingForm.controls.pickupDateTime.value) {
      this.dataForFareEstimate.pickupDateTime =
        this.manualBookingForm.controls.pickupDateTime.value.getTime() +
        this.selectedTimeInMillis;
    }
    this.commonService
      .getFareEstimate(this.serviceTypeId, this.dataForFareEstimate)
      .subscribe(
        (result) => {
          this.manualBookingForm.controls.estimateId.setValue(
            result.estimateId
          );
          this.showBookingFareDialog = true;
          // this.childView.calculateFares(result);
          // this.childView.getCardNumber(this.cardDetails.cardNumber, this.manualBookingForm.controls.paymentType.value);
        },
        (error) => {
          this.showBookingFareDialog = false;
          this.showMessage("Error", error.general[0].message);
        }
      );
  }

  getFareEstmateForRideShare() {
    const controls = this.manualBookingForm.controls;
    const inputData = {
      serviceTypeId: controls.serviceTypeId.value,
      sourceLatitude: controls.sourceLatitude.value,
      sourceLongitude: controls.sourceLongitude.value,
      destinationLatitude: controls.destinationLatitude.value,
      destinationLongitude: controls.destinationLongitude.value,
      promoCode: controls.promoCode.value,
      rideShareNumOfPassenger: this.noOfSeats,
    };
    this.commonService.getFareEstimateForRideShare(inputData).subscribe(
      (result) => {
        this.manualBookingForm.controls.estimateId.setValue(result.estimateId);
        this.showBookingFareDialog = true;
        // this.childView.calculateFares(result);
        // this.childView.getCardNumber(this.cardDetails.cardNumber, this.manualBookingForm.controls.paymentType.value);
      },
      (error) => {
        this.showBookingFareDialog = false;
        this.showMessage("Error", error.general[0].message);
      }
    );
  }

  applyPromoCode(event) {
    if (this.manualBookingForm.controls.promoCode.value !== "") {
      this.getFareEstimates();
    }
  }

  changeNoOfSeats() {
    this.noOfSeats = this.manualBookingForm.controls.noOfSeats.value;
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
  }

  addRideLaterComponent() {
    this.resetValidator();
    switch (this.rideTypeValue) {
      case "1":
        this.manualBookingForm.controls.pickupDateTime.clearValidators();
        // this.manualBookingForm.controls.rideLaterPickupTime.setValidators(null);
        this.manualBookingForm.controls.pickupDateTime.setValue("");
        this.manualBookingForm.controls.pickupDateTime.updateValueAndValidity();
        // this.manualBookingForm.controls.rideLaterPickupTime.updateValueAndValidity();
        break;
      case "2":
        this.manualBookingForm
          .get("pickupDateTime")
          .setValidators([Validators.required]);
        // this.manualBookingForm.get("rideLaterPickupTime").setValidators([Validators.required]);
        // this.manualBookingForm.controls.pickupDateTime.setValue("");
        this.manualBookingForm.controls.pickupDateTime.updateValueAndValidity();
        // this.manualBookingForm.controls.rideLaterPickupTime.updateValueAndValidity();
        break;
      case "3":
        this.disableVehicleType = true;
        this.manualBookingForm.controls.vehicleTypeId.setValue("");
        this.manualBookingForm
          .get("noOfSeats")
          .setValidators([Validators.required]);
        this.manualBookingForm.get("vehicleTypeId").setValidators(null);
        this.manualBookingForm.controls.pickupDateTime.updateValueAndValidity();
        // this.manualBookingForm.controls.rideLaterPickupTime.updateValueAndValidity();
        break;
      default:
        break;
    }
    this.updateValidator();
    this.getNearbyVehicles();
  }

  resetValidator() {
    this.disableVehicleType = false;
    this.manualBookingForm.controls.vehicleTypeId.setValidators([
      Validators.required,
    ]);
    this.manualBookingForm.controls.pickupDateTime.setValidators(null);
    // this.manualBookingForm.controls.rideLaterPickupTime.setValidators(null);
    this.manualBookingForm.controls.noOfSeats.setValidators(null);
    // this.manualBookingForm.controls.rideLaterPickupTime.setValue("");
  }

  updateValidator() {
    this.manualBookingForm.controls.pickupDateTime.updateValueAndValidity();
    // this.manualBookingForm.controls.rideLaterPickupTime.updateValueAndValidity();
    this.manualBookingForm.controls.noOfSeats.updateValueAndValidity();
    this.manualBookingForm.controls.vehicleTypeId.updateValueAndValidity();
  }

  setNoOfSeats() {
    this.noOfSeatsList.push({
      label: this.commonBindingDataService.getLabel("please_select"),
      value: "",
    });
    for (let seat = 1; seat <= 4; seat++) {
      this.noOfSeatsList.push({ label: seat, value: seat });
    }
  }

  onClickBookNow(event) {
    if (this.manualBookingForm.controls.paymentType.value === "CARD") {
      this.cardDetails = {};
      this.getCardDetails();
    }

    if (!this.hasErrorInForm()) {
      this.getFareEstimates();
    }
  }

  onDialogButtonClick(event) {
    // this.manualBookingData.passengerType;
    // console.log(this.manualBookingForm);
    this.displaySaveButton = true;

    if (event === "confirmed") {
      this.showBookingFareDialog = false;

      if (
        this.cardDetails.cardNumber ||
        this.manualBookingForm.controls.paymentType.value === "CASH" ||
        this.manualBookingForm.controls.paymentType.value === "WALLET" ||
        this.manualBookingForm.controls.paymentType.value === "CREDITS"
      ) {
        if (this.rideTypeValue !== "3") {
          this.genetateRideNowInput();
          if (this.serviceTypeId === AppSettings.TAXI) {
            this.commonService
              .bookRideNowAndRideLater(
                this.serviceTypeId,
                this.manualBookingData
              )
              .subscribe(
                (result) => {
                  // this.redirectUrl(result.general[0].message);
                  this.displaySaveButton = false;

                  this.showMessage("Success", result.general[0].message);
                  setTimeout(() => {
                    this.message = [];
                    this.origin = null;
                    this.destination = null;
                    this.manualBookingForm.reset();
                    this.formValidation();
                    this.setDefaultValuesForFands();
                    this.clearMarker();
                    this.showPosition();
                    window.location.reload();
                    // this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);
                    // window.location.reload();
                  }, 2000);
                  // this.router.navigate(['bookings'], { queryParams: { severity: 'Success', message: result.general[0].message } });
                  // this.router.navigate(['bookings'], { queryParams: { message: result.general[0].message } });
                },
                (error) => {
                  this.displaySaveButton = false;
                  this.showMessage("Error", error.general[0].message);
                  this.errorBindingService.onAPIValidationError(
                    error,
                    this.manualBookingErrors
                  );
                }
              );
          } else if (this.serviceTypeId === AppSettings.RENTAL) {
            this.commonService
              .bookRideNowAndRideLaterRental(this.manualBookingData)
              .subscribe(
                (result) => {
                  // this.redirectUrl(result.general[0].message);
                  this.displaySaveButton = false;
                  this.showMessage("Success", result.general[0].message);
                  setTimeout(() => {
                    this.message = [];
                    this.router.navigate(["bookings"]);
                  }, 2000);
                  // this.router.navigate(['bookings'], { queryParams: { severity: 'Success', message: result.general[0].message } });
                  // this.router.navigate(['bookings'], { queryParams: { message: result.general[0].message } });
                },
                (error) => {
                  this.displaySaveButton = false;
                  this.showMessage("Error", error.general[0].message);
                  this.errorBindingService.onAPIValidationError(
                    error,
                    this.manualBookingErrors
                  );
                }
              );
          } else if (this.serviceTypeId === AppSettings.OCCASIONAL_RIDE) {
            this.commonService
              .bookRideNowAndRideLaterOccasional(
                this.serviceTypeId,
                this.manualBookingData
              )
              .subscribe(
                (result) => {
                  // this.redirectUrl(result.general[0].message);
                  this.displaySaveButton = false;
                  this.showMessage("Success", result.general[0].message);
                  setTimeout(() => {
                    this.message = [];
                    this.router.navigate(["bookings"]);
                  }, 2000);
                  // this.router.navigate(['bookings'], { queryParams: { severity: 'Success', message: result.general[0].message } });
                  // this.router.navigate(['bookings'], { queryParams: { message: result.general[0].message } });
                },
                (error) => {
                  this.displaySaveButton = false;
                  this.showMessage("Error", error.general[0].message);
                  this.errorBindingService.onAPIValidationError(
                    error,
                    this.manualBookingErrors
                  );
                }
              );
          }
        } else {
          this.commonService
            .bookRideShare(this.generateRideShareInput())
            .subscribe(
              (result) => {
                // this.redirectUrl(result.general[0].message);
                this.displaySaveButton = false;
                this.showMessage("Success", result.general[0].message);
                setTimeout(() => {
                  this.message = [];
                  this.router.navigate(["bookings"]);
                }, 2000);
              },
              (error) => {
                this.displaySaveButton = false;
                this.showMessage("Error", error.general[0].message);
                this.errorBindingService.onAPIValidationError(
                  error,
                  this.manualBookingErrors
                );
              }
            );
        }
      } else {
        if (this.rideTypeValue !== "3") {
          this.genetateRideNowInput();

          this.commonBindingDataService.rideType = this.rideTypeValue;
          this.commonBindingDataService.manualBookingData = this.manualBookingData;
        } else {
          this.commonBindingDataService.rideType = "3";
          this.commonBindingDataService.manualBookingData = this.generateRideShareInput();
        }

        // this.commonService.manualBookingData = this.manualBookingData;
        this.router.navigate(["payment"]);
      }
    }
    else if (event === "back") {
      this.showBookingFareDialog = false;
      console.log(this.manualBookingForm.controls.rideType.value);
      if (this.manualBookingForm.controls.rideType.value == 1) {
        this.manualBookingForm.controls.pickupDateTime.clearValidators();
        this.manualBookingForm.controls.pickupDateTime.updateValueAndValidity();
      }
    }
    else {
      this.showBookingFareDialog = false;
    }
  }

  getCardDetails() {
    if (this.userid) {
      this.commonBindingDataService.getCardDetails(this.userid).subscribe(
        (result) => {
          this.cardDetails = result;
          this.commonBindingDataService.carddetails = this.cardDetails;
        },
        (error) => {
          this.cardDetails.cardNumber = null;
          this.showMessage("Error", error.general[0].message);
        }
      );
    }
  }

  generateRideShareInput() {
    const controls = this.manualBookingForm.controls;
    return new RideShareModel({
      tenantId: AppSettings.TENANT,
      estimateId: controls.estimateId.value,
      vehicleTypeId: controls.vehicleTypeId.value,
      serviceTypeId: controls.serviceTypeId.value,
      pickupLocationName: controls.pickupLocationName.value,
      sourceLatitude: controls.sourceLatitude.value,
      sourceLongitude: controls.sourceLongitude.value,
      dropLocationName: controls.dropLocationName.value,
      destinationLatitude: controls.destinationLatitude.value,
      destinationLongitude: controls.destinationLongitude.value,
      paymentType: controls.paymentType.value,
      passengerType: controls.passengerType.value,
      pEmail: controls.email.value,
      pNumCountryCode: controls.phoneCode.value,
      pPhoneNum: controls.phone.value,
      pNetId: controls.netId.value,
      pFirstName: controls.firstName.value,
      pLastName: controls.lastName.value,
      stripeCardId: "",
      // passengerId: this.isAppUsersPassengerType === '1' ? this.passengerDataSet[this.selectedPassengerIndex].userid : null,
      // passengerId: this.passengerDataSet[this.selectedPassengerIndex].userid,
      passengerId: this.userid,
      driverId:
        this.isManualDriverType === "MANUAL"
          ? this.driverDataSet[this.selectedDriverIndex].userId
          : null,
      rideShareNumOfPassenger: controls.noOfSeats.value,
      passengerDetails:
        this.isAppUsersPassengerType === "2"
          ? this.newPassengerForm.value
          : null,
      tripNote: controls.note.value,
    });
  }

  genetateRideNowInput() {
    const controls = this.manualBookingForm.controls;
    this.manualBookingData.tenantId = AppSettings.TENANT;
    this.manualBookingData.estimateId = controls.estimateId.value;
    this.manualBookingData.vehicleTypeId = controls.vehicleTypeId.value;
    this.manualBookingData.pickupLocationName =
      controls.pickupLocationName.value;
    this.manualBookingData.sourceLatitude = controls.sourceLatitude.value;
    this.manualBookingData.sourceLongitude = controls.sourceLongitude.value;
    this.manualBookingData.dropLocationName = controls.dropLocationName.value;
    this.manualBookingData.destinationLatitude =
      controls.destinationLatitude.value;
    this.manualBookingData.destinationLongitude =
      controls.destinationLongitude.value;
    this.manualBookingData.paymentType = controls.paymentType.value;
    // this.manualBookingData.promoCode = controls.promoCode.value;
    this.manualBookingData.rideLater =
      parseInt(controls.rideType.value, 10) === 1 ? false : true;
    // this.manualBookingData.changePassengerType = controls.passengerTypes.value;
    // if (controls.passengerTypes.value == 'taxiPulseCorporatePassenger00007') {
    //   this.manualBookingData.corporateBooking = true;
    // } else {
    //   this.manualBookingData.corporateBooking = false;
    // }
    this.manualBookingData.passengerType = controls.passengerType.value;
    // this.manualBookingData.passengerId = this.isAppUsersPassengerType === '1' ?
    //   this.passengerDataSet[this.selectedPassengerIndex].userid : null;
    // this.manualBookingData.passengerId = this.passengerDataSet[this.selectedPassengerIndex].userid;
    this.manualBookingData.passengerId = this.userid;
    // this.manualBookingData.pickupDateTime = controls.pickupDateTime.value;
    this.manualBookingData.tripNote = controls.note.value;
    // this.manualBookingData.pEmail = controls.email.value;
    this.manualBookingData.pNumCountryCode = controls.phoneCode.value;
    this.manualBookingData.pPhoneNum = controls.phone.value;
    this.manualBookingData.pNetId = controls.netId.value;
    this.manualBookingData.pFirstName = controls.firstName.value;
    this.manualBookingData.pLastName = controls.lastName.value;
    this.manualBookingData.serviceTypeId = controls.serviceTypeId.value;
    this.manualBookingData.tourLocationsList = controls.stops.value;
    this.manualBookingData.tourPassengersList = controls.passenger.value;
    this.manualBookingData.workOrderId = controls.workOrder.value;

    this.manualBookingData.stripeCardId = "";

    // this.manualBookingData.pickupDateTime = moment(controls.pickupDateTime.value).valueOf();
    this.manualBookingData.driverId =
      this.isManualDriverType === "MANUAL"
        ? this.driverDataSet[this.selectedDriverIndex].userId
        : null;
    // this.manualBookingData.rideLaterPickupTime = moment(
    //   controls.rideLaterPickupTime.value
    // ).valueOf();
    this.manualBookingData.passengerDetails =
      this.isAppUsersPassengerType === "2" ? this.newPassengerForm.value : null;
    if (controls.rideType.value === "2") {
      this.manualBookingData.pickupDateTime = controls.pickupDateTime.value.getTime();
      // this.manualBookingData.pickupDateTime = controls.rideLaterPickupTime.value.getTime() + this.selectedTimeInMillis;
      // this.manualBookingData.pickupDateTime = controls.pickupDateTime.value.getTime() + this.selectedTimeInMillis;

      // this.manualBookingData.pickupDateTime = this.toCurrentTimeZone(this.manualBookingData.pickupDateTime);
    }
  }

  toCurrentTimeZone(now) {
    const date = new Date(now);
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).getTime();
  }

  getDateInMillis(event) {
    const setMinValidTime = new Date(
      moment(this.minDate).add(1, "m").valueOf()
    );
    this.startOfSelectedDate = moment(event).startOf("day").valueOf();
    this.dateSelected = true;
    this.manualBookingForm.controls.pickupDateTime.enable();
    this.manualBookingForm.controls.pickupDateTime.setValue(
      setMinValidTime
    );
    this.getTime(setMinValidTime);
    this.getDriversList("");
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.manualBookingForm.controls.driverType.setValue(
      AppSettings.DRIVER_TYPES_MANUAL_BOOKING[2].value
    );
    this.isManualDriverType = "";
    this.selectedDriverIndex = -1;
  }

  getTime(event) {
    const hours = moment(event).hour();
    const mins = moment(event).minute();
    const millisInAMinute = 60000;
    this.selectedTimeInMillis = (hours * 60 + mins) * millisInAMinute;
    this.calculateBookingTime();
    // this.manualBookingForm.controls.driverType.reset();
    this.isManualDriverType = "";
    this.selectedDriverIndex = -1;
  }

  calculateBookingTime() {
    const minDateInMillis = moment(this.minDate).utc().valueOf();
    const maxDateInMillis = moment(this.maxDate).utc().valueOf();
    const selectedPickUpTime =
      this.startOfSelectedDate + this.selectedTimeInMillis;
    if (
      minDateInMillis > selectedPickUpTime ||
      selectedPickUpTime > maxDateInMillis
    ) {
      this.invalidateManualBookingFormDueToInvalidRideLaterTime();
    } else {
      this.validateManualBookingFormDueToInvalidRideLaterTime();
    }
    // this.checkTimeLessOrNotForcurrentDate();
  }

  calculateBookingTimeWhileSubmit() {
    const minDateInMillis = moment(this.minDate).utc().valueOf();
    const maxDateInMillis = moment(this.maxDate).utc().valueOf();
    const selectedPickUpTime =
      this.startOfSelectedDate + this.selectedTimeInMillis;
    if (
      minDateInMillis > selectedPickUpTime ||
      selectedPickUpTime > maxDateInMillis
    ) {
      this.invalidateManualBookingFormDueToInvalidRideLaterTime();
      return true;
    } else {
      this.validateManualBookingFormDueToInvalidRideLaterTime();
      return true;
    }
  }

  // For F&S
  // checkTimeLessOrNotForcurrentDate() {
  //   let rideLaterTime = moment(this.manualBookingForm.controls.rideLaterPickupTime.value).valueOf();
  //   let pickupDateTime = moment(this.manualBookingForm.controls.pickupDateTime.value).valueOf();
  //   let currentTime = new Date().getTime();
  //   if(rideLaterTime < currentTime && pickupDateTime < currentTime){
  //     let nexDay = new Date(moment(this.manualBookingForm.controls.pickupDateTime.value).add(1, 'days').valueOf());
  //     this.manualBookingForm.controls.pickupDateTime.setValue(new Date(nexDay));
  //     this.manualBookingForm.controls.pickupDateTime.setValue(new Date(nexDay));
  //   }else{
  //     this.manualBookingForm.controls.pickupDateTime.setValue( new Date(new Date().setHours(0, 0, 0, 0)));
  //   }
  // }

  invalidateManualBookingFormDueToInvalidRideLaterTime() {
    this.manualBookingForm.controls.pickupDateTime.setErrors({
      incorrect: true,
    });
    let mintime = moment(this.minDate.getTime()).format("H:mm"); // formatDateCell(moment(this.minDate).utc().valueOf())
    let maxtime = moment(this.maxDate.getTime()).format("H:mm");
    this.timeNotInRangeError =
      "Please select valid time for ride later booking.";
    // this.timeNotInRangeError = `Please select time within ${mintime}  to  ${maxtime}.`;
  }

  validateManualBookingFormDueToInvalidRideLaterTime() {
    this.manualBookingForm.controls.pickupDateTime.setErrors(null);
    this.timeNotInRangeError = "";
  }

  selectDriver(event, index) {
    this.selectedDriverIndex = index;
  }

  unselectDriver(event, index) {
    this.selectedDriverIndex = -1;
  }

  selectUser(event, index) {
    this.selectedPassengerIndex = index;
  }

  unselectUser(event, index) {
    this.selectedPassengerIndex = -1;
  }

  getDriversList(searchtext?) {
    const serviceType = this.manualBookingForm.controls.serviceTypeId.value;
    let isValid: Boolean = false;

    this.driverDataSet = [];
    // let pickUpDateRideLater = this.manualBookingData.controls.pickupDateTime.value;
    if (this.rideTypeValue === "1") {
      isValid = this.getDriversListRideNowValidation();
    } else if (this.rideTypeValue === "2") {
      isValid = this.getDriversListRideLaterValidation();
    } else if (this.rideTypeValue === "3") {
      isValid = this.getDriversListRideShareValidation();
    }

    let ridelaterTime = 0;

    if (this.manualBookingForm.controls.pickupDateTime.value) {
      ridelaterTime =
        this.manualBookingForm.controls.pickupDateTime.value.getTime() +
        this.selectedTimeInMillis;
    }

    if (isValid) {
      if (
        this.manualBookingForm.controls.serviceTypeId.value ===
        AppSettings.RENTAL &&
        !this.manualBookingForm.controls.dropLocationName.value
      ) {
        this.destination = new google.maps.LatLng(0, 0);
        this.commonService
          .getDriversList(
            this.origin.lat(),
            this.origin.lng(),
            this.destination.lat(),
            this.destination.lng(),
            this.noOfSeats,
            this.rideTypeValue,
            this.vehicleTypeId,
            searchtext,
            ridelaterTime,
            serviceType
          )
          .subscribe((results) => {
            this.driverDataSet = results;
          });
      } else {
        this.commonService
          .getDriversList(
            this.origin.lat(),
            this.origin.lng(),
            this.destination.lat(),
            this.destination.lng(),
            this.noOfSeats,
            this.rideTypeValue,
            this.vehicleTypeId,
            searchtext,
            ridelaterTime,
            serviceType
          )
          .subscribe((results) => {
            this.driverDataSet = results;
          });
      }
    }
  }

  getDriversListRideNowValidation() {
    if (
      this.manualBookingForm.controls.serviceTypeId.value === AppSettings.TAXI
    ) {
      if (
        this.manualBookingForm.controls.driverType.value &&
        this.manualBookingForm.controls.rideType.value &&
        this.manualBookingForm.controls.vehicleTypeId.value &&
        this.origin &&
        this.destination
      ) {
        return true;
      }
    } else {
      if (
        this.manualBookingForm.controls.driverType.value &&
        this.manualBookingForm.controls.rideType.value &&
        this.manualBookingForm.controls.vehicleTypeId.value &&
        this.origin
      ) {
        return true;
      }
    }

    return false;
  }

  getDriversListRideLaterValidation() {
    if (
      this.getDriversListRideNowValidation() &&
      this.manualBookingForm.controls.pickupDateTime.value
    ) {
      return true;
    }
    return false;
  }

  getDriversListRideShareValidation() {
    if (this.manualBookingForm.controls.noOfSeats.value) {
      return true;
    }
    return false;
  }

  searchDriver(event) {
    this.selectedDriverIndex = -1;
    const searchData = this.searchDriverForm.controls.searchDriver.value;
    this.getDriversList(searchData);
  }

  changeDriverType(event) {
    if (event.value === AppSettings.DRIVER_TYPES_MANUAL_BOOKING[1].value) {
      this.isManualDriverType = "MANUAL";
      this.getDriversList("");
    } else {
      this.isManualDriverType = "";
      this.driverDataSet = [];
    }
    if (event.value === AppSettings.DRIVER_TYPES_MANUAL_BOOKING[2].value) {
      this.isManualDriverType = "AUTO";
      this.getDriversList("");
    }
  }

  searchUser(event) {
    this.selectedPassengerIndex = -1;
    const searchData = this.searchPassengerForm.controls.searchPassenger.value;
    this.getPassengersList(searchData);
  }

  getPassengersList(searchtext?) {
    this.commonService.getPassengersList(searchtext).subscribe((results) => {
      this.passengerDataSet = results;
    });
  }

  changePassengerType(event) {
    this.paymentTypeOptions = [];
    this.isAppUsersPassengerType = event.value;
    if (event.value === "taxiPulseCorporatePassenger00007") {
      this.paymentTypeOptions = this.creditPaymentTypes;
    } else {
      this.paymentTypeOptions = this.paymentTypeOptions1;
    }
    this.paymentTypeOptions = this.paymentTypeOptions1;

    // this.manualBookingForm.controls.passengerTypes.setValidators([Validators.required]);
  }

  getNearbyVehicles() {
    if (this.origin !== null && this.serviceTypeId !== null) {
      if (this.rideTypeValue !== "3") {
        if (this.vehicleTypeId !== null) {
          this.getNearByVehicleForRideNowAndLater();
        }
      } else {
        if (this.destination !== null) {
          this.getNearByVehicleForRideShare();
        }
      }
    }
  }

  getNearByVehicleForRideNowAndLater() {
    this.redrawMap();
    // this.commonService.getNearByVehicles(this.origin.lat(), this.origin.lng(), this.destination.lat(),
    //   this.destination.lng(), this.DEFAULT_RADIOUS,
    //   this.serviceTypeId, this.vehicleTypeId).subscribe(results => {
    //     if (results.nearyByDrivers.length > 0 || this.rideTypeValue === '2') {
    //       this.nearbyCarsList = results.nearyByDrivers;
    //       this.redrawMap();
    //     } else {
    //       this.nearbyCarsList = [];
    //       this.clearMarker();
    //       this.showMessage('Error', this.commonBindingDataService.getLabel('label_no_nearby_driver'));
    //     }
    //   }, (error) => {
    //     this.showMessage('Error', error.general[0].message);
    //   });
  }

  getNearByVehicleForRideShare() {
    this.commonService
      .getNearByVehiclesForRideShare(
        this.origin.lat(),
        this.origin.lng(),
        this.destination.lat(),
        this.destination.lng(),
        this.serviceTypeId
      )
      .subscribe(
        (results) => {
          if (results.nearyByDrivers.length > 0 || this.rideTypeValue === "2") {
            this.nearbyCarsList = results.nearyByDrivers;
            this.redrawMap();
          } else {
            this.nearbyCarsList = [];
            this.clearMarker();
            this.showMessage(
              "Error",
              this.commonBindingDataService.getLabel("label_no_nearby_driver")
            );
          }
        },
        (error) => {
          this.showMessage("Error", error.general[0].message);
        }
      );
  }

  changeRideType(event, fromTs?) {
    this.vehicleTypeId = null;
    // this.manualBookingForm.controls.vehicleTypeId.reset();  // comment for F&S only
    this.rideTypeValue = event.value;
    if (fromTs) {
      this.rideTypeValue = fromTs;
    }
    this.addRideLaterComponent();
    this.getDriversList("");
    this.manualBookingForm.controls.driverType.setValue(
      AppSettings.DRIVER_TYPES_MANUAL_BOOKING[2].value
    );
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
  }

  changeVehicleType(event?, defaultVehicleType?) {
    if (defaultVehicleType) {
      this.vehicleTypeId = defaultVehicleType;
    } else {
      this.vehicleTypeId = event.value;
    }

    if (this.serviceTypeId === AppSettings.OCCASIONAL_RIDE) {
      this.getServiceFromCities();
    } else {
      this.getDriversList("");
      this.getNearbyVehicles();
      this.manualBookingForm.controls.driverType.setValue(
        AppSettings.DRIVER_TYPES_MANUAL_BOOKING[2].value
      );
      // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
      this.isManualDriverType = "";
      this.selectedDriverIndex = -1;
      this.getPackages();
    }
  }

  changeServiceType(event) {
    this.serviceTypeId = event.value;
    if (this.serviceTypeId === AppSettings.OCCASIONAL_RIDE) {
      this.manualBookingForm.controls.serviceFrom.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.serviceTo.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.dropLocationName.setValidators([]);
      this.manualBookingForm.controls.pickupLocationName.setValidators([]);
      this.manualBookingForm.controls.dropLocationName.updateValueAndValidity();
      this.manualBookingForm.controls.pickupLocationName.reset();
      this.manualBookingForm.controls.vehicleTypeId.reset();

      // this.isSameCitiesError = false;
      this.isOccasionalTrip = true;
      this.serviceFromOptions = [];
      this.serviceToOptions = [];
    } else {
      if (this.displayDropStar) {
        this.manualBookingForm.controls.dropLocationName.setValidators([
          Validators.required,
        ]);
      } else {
        this.manualBookingForm.controls.dropLocationName.setValidators([]);
      }
      this.manualBookingForm.controls.pickupLocationName.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.dropLocationName.updateValueAndValidity();
      this.manualBookingForm.controls.pickupLocationName.reset();

      this.manualBookingForm.controls.serviceFrom.setValidators([]);
      this.manualBookingForm.controls.serviceTo.setValidators([]);
      this.manualBookingForm.controls.serviceFrom.reset();
      this.manualBookingForm.controls.serviceTo.reset();
      this.manualBookingForm.controls.vehicleTypeId.reset();
      // this.isSameCitiesError = true;
      this.isOccasionalTrip = false;
    }

    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
    this.selectedDriverIndex = -1;

    this.getVehicleTypes(this.serviceTypeId);
    this.directionsDisplay.setMap(null);

    // this.origin = new google.maps.LatLng(null, null);
    // this.destination = new google.maps.LatLng(null, null);
    // this.redrawMap();

    // this.getNearbyVehicles();
    this.getRideTypes(this.serviceTypeId);
    this.setDropLocationValidators(this.serviceTypeId);
    this.packageDropDownVisibility();
    this.getPackages();
    this.manualBookingForm.controls.packageId.reset();
  }

  setDropLocationValidators(serviceType) {
    if (serviceType === AppSettings.TAXI) {
      this.displayDropStar =
        this.isDestinationOptional === "false" ? false : true;

      // this.manualBookingForm.controls.dropLocationName.setValidators(this.isDestinationOptional ? [Validators.required] : null);
      if (this.displayDropStar) {
        this.manualBookingForm.controls.dropLocationName.setValidators([
          Validators.required,
        ]);
      } else {
        this.manualBookingForm.controls.dropLocationName.setValidators([]);
      }
      this.manualBookingForm.controls.destinationLatitude.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.destinationLongitude.setValidators([
        Validators.required,
      ]);
      this.manualBookingForm.controls.dropLocationName.updateValueAndValidity();
      this.manualBookingForm.controls.destinationLatitude.updateValueAndValidity();
      this.manualBookingForm.controls.destinationLongitude.updateValueAndValidity();
      // this.manualBookingForm.updateValueAndValidity();
    } else {
      this.displayDropStar = false;
      this.manualBookingForm.controls.dropLocationName.setValidators([]);
      this.manualBookingForm.controls.destinationLatitude.setValue(0);
      this.manualBookingForm.controls.destinationLongitude.setValue(0);
      this.manualBookingForm.controls.dropLocationName.updateValueAndValidity();
      this.manualBookingForm.controls.destinationLatitude.updateValueAndValidity();
      this.manualBookingForm.controls.destinationLongitude.updateValueAndValidity();
      // this.manualBookingForm.updateValueAndValidity();
    }
  }

  resetTaxiRentalLatLong() {
    this.manualBookingForm.controls.pickupLocationName.reset();
    this.manualBookingForm.controls.sourceLatitude.reset();
    this.manualBookingForm.controls.sourceLongitude.reset();

    this.manualBookingForm.controls.dropLocationName.reset();
    this.manualBookingForm.controls.destinationLatitude.reset();
    this.manualBookingForm.controls.destinationLongitude.reset();
  }

  resetOccasionalLatLong() {
    this.manualBookingForm.controls.serviceFrom.reset();
    this.manualBookingForm.controls.sourceLatitude.reset();
    this.manualBookingForm.controls.sourceLongitude.reset();

    this.manualBookingForm.controls.serviceTo.reset();
    this.manualBookingForm.controls.destinationLatitude.reset();
    this.manualBookingForm.controls.destinationLongitude.reset();
  }

  getRideTypes(rideType) {
    this.rideTypes = this.rideTypeOptions;

    // if (rideType == 'tenantServiceTypes00000000000001') {
    //   this.rideTypes = this.rideTypeOptions;
    // } else {
    //   this.rideTypes = this.rideTypeOptionsForRental;
    // }
  }

  // clear previous value
  onTypePickup() {
    // this.manualBookingForm.controls.sourceLatitude.setValue('');
    // this.manualBookingForm.controls.sourceLongitude.setValue('');
  }

  setPickupPoint(event) {
    this.manualBookingForm.controls.pickupLocationName.setValue(event.address);
    this.manualBookingForm.controls.sourceLatitude.setValue(event.lat);
    this.manualBookingForm.controls.sourceLongitude.setValue(event.lng);
    this.origin = new google.maps.LatLng(event.lat, event.lng);
    // this.packageDropDownVisibility();
    this.getPackages();
    this.getNearbyVehicles();
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
  }

  setDropPoint(event) {
    this.manualBookingForm.controls.dropLocationName.setValue(event.address);
    this.manualBookingForm.controls.destinationLatitude.setValue(event.lat);
    this.manualBookingForm.controls.destinationLongitude.setValue(event.lng);
    this.destination = new google.maps.LatLng(event.lat, event.lng);
    this.getNearbyVehicles();
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
    // this.packageDropDownVisibility();
    // this.getPackages();
  }

  packageDropDownVisibility() {
    if (
      this.manualBookingForm.controls.serviceTypeId.value ===
      AppSettings.RENTAL &&
      this.manualBookingForm.controls.vehicleTypeId.value &&
      this.manualBookingForm.controls.pickupLocationName.value &&
      this.manualBookingForm.controls.sourceLatitude &&
      this.manualBookingForm.controls.sourceLongitude
    ) {
      this.serviceTypeValue = true;
      return true;
    } else {
      this.serviceTypeValue = false;
      return false;
    }
  }

  redrawMap() {
    if (this.directionsDisplay !== null) {
      this.directionsDisplay.setMap(null);
      this.directionsDisplay = null;
    }

    this.directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: AppSettings.DEFAULT_ROUTE_COLOR,
      },
    });

    this.directionsDisplay.setMap(this.bookingMap);
    this.drawNearbyCars();

    if (this.origin !== null && this.destination !== null) {
      this.directionsService.route(
        {
          origin: this.origin,
          destination: this.destination,
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            this.directionsDisplay.setDirections(response);
            this.directionsDisplay.setMap(this.bookingMap);
          } else {
            this.showMessage("Error", status);
          }
        }
      );
    }
  }

  drawNearbyCars() {
    this.clearMarker();
    for (let car = 0; car < this.nearbyCarsList.length; car++) {
      this.addMarker(
        new google.maps.LatLng(
          this.nearbyCarsList[car].latitude,
          this.nearbyCarsList[car].longitude
        )
      );
    }
  }

  addMarker(location) {
    const marker = new google.maps.Marker();
    marker.setMap(this.bookingMap);
    marker.setIcon("/assets/images/temp/taxi_free.png");
    marker.setPosition(location);
    this.markers.push(marker);
  }

  clearMarker() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setPosition(null);
      this.markers[i].setMap(null);
    }
  }

  onClickBack() {
    // window.open("https://www.google.com", "_self");
    // window.location.reload();

    this.origin = null;
    this.destination = null;
    // this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);
    this.manualBookingForm.reset();
    this.manualBookingForm.controls.pickupLocationName.reset();
    this.clearMarker();
    this.formValidation();
    this.setDefaultValuesForFands(); // for F&ss
    this.showPosition();
  }

  redirectUrl(paramMessage?) {
    this.router.navigate(["bookings"], {
      queryParams: { message: paramMessage },
    });
  }

  // changePaymentType(event) {
  //   const data = event.value;
  //   switch (data) {
  //     case 'CASH':
  //       this.amountPopup();
  //       break;
  //     case 'CARD':
  //       this.amountPopup();
  //       break;
  //     case 'WALLET':
  //       this.amountPopup();
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // amountPopup() {
  //   this.confirmationService.confirm({
  //     message: 'Estimated Fare is' + + 'Click ok to proceed?',
  //     accept: () => {
  //       this.getFareEstimates();
  //       // if (data.active === true) {
  //       //   data.active = false;
  //       // } else {
  //       //   data.active = true;
  //       // }
  //       // this.activeDeactivePackages(data.id, data.active, data.tenantId);
  //     }
  //   });
  // }

  hasErrorInForm() {
    if (
      this.isAppUsersPassengerType === "1" &&
      this.selectedPassengerIndex < 0
    ) {
      this.showMessage("Error", "Please select valid app user.");
      return true;
    }

    if (this.isAppUsersPassengerType === "2" && !this.newPassengerForm.valid) {
      this.showMessage("Error", "Please fill up guest user details.");
      return true;
    }

    if (this.isManualDriverType === "MANUAL" && this.selectedDriverIndex < 0) {
      this.showMessage("Error", "Please select driver from driver list.");
      return true;
    }

    if (!this.calculateBookingTimeWhileSubmit()) {
      return true;
    }

    return false;
  }

  // checkRenatal() {
  //   if (this.serviceTypeId == 'Rental' && this.vehicleTypeId != 'undefined' && this.vehicleTypeId != 'null') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  getServiceArea() {
    // this.cityOptions.push({ label: this.commonBindingDataService.getLabel('label_all'), value: '' });
    this.commonBindingDataService.getCities().subscribe(
      (results) => {
        for (let city = 0; city < results.length; city++) {
          this.cityOptions.push({
            label: results[city].cityAliasName,
            value: {
              cityId: results[city].id,
              cityName: results[city].cityName,
              lat: results[city].latitude,
              lang: results[city].longitude,
            },
          });
        }
      },
      (error) => {
        this.showMessage("Error", error.general[0].message);
      }
    );
  }

  /*
  isSameServiceCitiesFrom(event) {
    this.manualBookingForm.controls.pickupLocationName.setValue(event.value.cityName);
    this.manualBookingForm.controls.sourceLatitude.setValue(event.value.lat);
    this.manualBookingForm.controls.sourceLongitude.setValue(event.value.lang);
    this.origin = new google.maps.LatLng(event.value.lat, event.value.lang);
    // this.packageDropDownVisibility();
    this.getPackages();
    this.getNearbyVehicles();
    this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = '';

    let serviceFrom = this.manualBookingForm.controls.serviceFrom.value;
    let serviceTo = this.manualBookingForm.controls.serviceTo.value;
    if (serviceFrom === serviceTo && serviceFrom !== '' && serviceTo !== '') {
      this.isSameCitiesError = true;
    } else {
      this.isSameCitiesError = false;
    }

  }

  isSameServiceCitiesTo(event) {
    this.manualBookingForm.controls.dropLocationName.setValue(event.value.cityName);
    this.manualBookingForm.controls.destinationLatitude.setValue(event.value.lat);
    this.manualBookingForm.controls.destinationLongitude.setValue(event.value.lang);
    this.destination = new google.maps.LatLng(event.value.lat, event.value.lang);
    this.getNearbyVehicles();
    this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = '';

    let serviceFrom = this.manualBookingForm.controls.serviceFrom.value;
    let serviceTo = this.manualBookingForm.controls.serviceTo.value;
    if (serviceFrom === serviceTo && serviceFrom !== '' && serviceTo !== '') {
      this.isSameCitiesError = true;
    } else {
      this.isSameCitiesError = false;
    }

  }
  */

  getServiceFromCities() {
    this.serviceFromOptions = [];
    this.serviceToOptions = [];
    const sourceCityId = "";

    this.commonBindingDataService
      .getCitiesForBooking(this.serviceTypeId, this.vehicleTypeId, sourceCityId)
      .subscribe(
        (results) => {
          for (let city = 0; city < results.length; city++) {
            this.serviceFromOptions.push({
              label: results[city].cityAliasName,
              value: {
                cityId: results[city].id,
                cityName: results[city].cityName,
                lat: results[city].latitude,
                lang: results[city].longitude,
              },
            });
          }
        },
        (error) => {
          this.showMessage("Error", error.general[0].message);
        }
      );
  }

  getServiceToCities(event) {
    this.manualBookingForm.controls.pickupLocationName.setValue(
      event.value.cityName
    );
    this.manualBookingForm.controls.sourceLatitude.setValue(event.value.lat);
    this.manualBookingForm.controls.sourceLongitude.setValue(event.value.lang);
    this.origin = new google.maps.LatLng(event.value.lat, event.value.lang);
    // this.packageDropDownVisibility();
    // this.getPackages();
    // this.getNearbyVehicles();

    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";

    const sourceCityId = event.value.cityId;
    this.serviceToOptions = [];
    this.commonBindingDataService
      .getCitiesForBooking(this.serviceTypeId, this.vehicleTypeId, sourceCityId)
      .subscribe(
        (results) => {
          for (let city = 0; city < results.length; city++) {
            this.serviceToOptions.push({
              label: results[city].cityAliasName,
              value: {
                cityId: results[city].id,
                cityName: results[city].cityName,
                lat: results[city].latitude,
                lang: results[city].longitude,
              },
            });
          }
        },
        (error) => {
          this.showMessage("Error", error.general[0].message);
        }
      );
  }

  setServiceToCities(event) {
    this.manualBookingForm.controls.dropLocationName.setValue(
      event.value.cityName
    );
    this.manualBookingForm.controls.destinationLatitude.setValue(
      event.value.lat
    );
    this.manualBookingForm.controls.destinationLongitude.setValue(
      event.value.lang
    );
    this.destination = new google.maps.LatLng(
      event.value.lat,
      event.value.lang
    );
    this.getNearbyVehicles();
    this.getDriversList("");
    // this.manualBookingForm.controls.driverType.setValue(AppSettings.DRIVER_TYPES_MANUAL_BOOKING[0].value);
    this.isManualDriverType = "";
  }

  // getCurrentLocation() {
  //   this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);
  //   // if (navigator.geolocation) {
  //   //   navigator.geolocation.getCurrentPosition(this.showPosition);
  //   //   alert('support')
  //   // } else {
  //   //   console.log("Geolocation is not supported by this browser.");
  //   //   alert(' not support')
  //   // }
  //   this.showPosition();
  // }

  showPosition(position?) {
    if (
      this.commonService.currentLatitude &&
      this.commonService.currentLongitude
    ) {
      this.options.center.lat = this.commonService.currentLatitude;
      this.options.center.lng = this.commonService.currentLongitude;
      let latLng = new google.maps.LatLng(
        this.commonService.currentLatitude,
        this.commonService.currentLongitude
      );
      // this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);

      // let latLng = new google.maps.LatLng(19.7514798,75.7138884);
      // this.marker = new google.maps.Marker({
      //   position: latLng,
      //   // icon: '/assets/images/temp/taxi_free.png',
      //   map: this.bookingMap,
      // });
    } else {
      this.options.center.lat = AppSettings.DEFAULT_LATITUDE;
      this.options.center.lng = AppSettings.DEFAULT_LONGITIDE;
      // this.bookingMap = new google.maps.Map(document.getElementById('booking-map'), this.options);
    }
    // console.log("Latitude: " + this.commonService.currentLatitude +
    //   "Longitude: " + this.commonService.currentLongitude);
  }
  // showMessage(severity, message) {
  //   this.message = [];
  //   this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  // }

  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

  ngOnDestroy() {
    this.subscribedVariable.unsubscribe();
    clearTimeout(this.cleartimer);
  }

  closeWarning() {
    this.bannerText = '';
  }
}
