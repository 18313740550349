import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { NotificationsService } from './../../services/notifications.service';
import { EditProfileComponent } from './../edit-profile/edit-profile.component';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'

})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(EditProfileComponent, { static: false }) editPro: EditProfileComponent;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  hasShowNotificationBar = false;
  notificationLabel;
  noNotificationFoundLabel;

  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService,
    private notificationsService: NotificationsService,
    private messageService: MessageService) {

  }

  ngOnInit() {
    this.notificationLabel = this.commonBindingDataService.getLabel('label_notifications');
    this.noNotificationFoundLabel = this.commonBindingDataService.getLabel('label_no_notification_found');
    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      // {
      //   label: 'Change password', icon: 'fa  fa-key', command: (event) => {
      //     this.changePassword();
      //   }
      // },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null
        && userObj.userDetails.profileImage !== null && userObj.userDetails.profileImage !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
    }

    this.subscription = this.uiService.profileUpdatedEvent
      .subscribe((state: string) => {
        this.updateProfileContent();
      });

    this.notificationProcessInitialization();

  }

  notificationProcessInitialization() {
    this.notificationsService.startFetchingNotifications(() => {
      this.getNotificationUnReadcountCount();
    });
  }

  getNotificationUnReadcountCount() {
    this.notificationsService.getCount().subscribe(data => {
      this.unReadNotificatonsCount = data.unReadcount;
    });
  }


  getNotificationListAndToggle(event) {
    this.unReadNotificatonsCount = 0;
    this.limit = 10;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    this.hasShowNotificationBar = true;
  }

  getNotificationList() {
    this.notificationsService.getDetails(this.limit, this.offset).subscribe(notification => {
      if (notification.data.length > 0) {
        this.offset = this.offset + notification.limit;
        this.notifications = [...this.notifications, ...notification.data];
      } else {
        this.showMessage('Success', this.noNotificationFoundLabel);
      }

    });
  }

  loadMoreNotification() {
    this.getNotificationList();
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.userCommandService.signOut().subscribe(results => {
      const logoutToken: any = this.storageService.getItem(AppSettings.LOGOUT_TOKEN_KEY);
      this.storageService.removeAll();
      this.storageService.setItem(AppSettings.LOGOUT_TOKEN_KEY, logoutToken);
      this.notificationsService.stopFetchingNotifications();
      this.router.navigate(['/signin']);
      this.notificationsService.canFetchNotification = false;
    }, (error) => {

      this.showMessage('Error', error.general[0].message);
    });
  }

  updateProfileContent() {
    this.userCommandService.getUserProfile().subscribe(results => {
      this.storageService.setItem(AppSettings.USER_DETAILS, results);
      AppSettings.USER = results;
      this.userFullName = results.firstName + ' ' + results.lastName;
      if (results.userDetails !== undefined && results.userDetails !== null && results.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + results.userDetails.profileImage;
      }
    }, (error) => {

      this.showMessage('Error', error.general[0].message);
    });
  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.editPro.setData();
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  ngOnDestroy() {
    this.notificationsService.stopFetchingNotifications();
    this.subscription.unsubscribe();
  }

  // showMessage(severity, message) {
  //   this.message = [];
  //   this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  // }
  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
