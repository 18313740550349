import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-activity',
  templateUrl: './dashboard-activity.component.html'
})
export class DashboardActivityComponent implements OnInit {

  @Input() headerLHS;
  @Input() headerRHS;
  @Input() activityNotifications;
  constructor() { }

  ngOnInit() {
  }

}
