import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-authorize-gateway',
  templateUrl: './authorize-gateway.component.html',
  styleUrls: ['./authorize-gateway.component.scss']
})
export class AuthorizeGatewayComponent implements OnInit {

  privacyPolicyData: any;
  authorizeForm: UntypedFormGroup;
  message: Message[] = [];
  authorizationKey;
  currentDate;
  month;
  year;
  showExpirationMessage = false;
  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.month = this.currentDate.getMonth() + 1;
    this.year = this.currentDate.getFullYear().toString().substr(-2);
    this.authorizeForm = this.formBuilder.group({
      cardNumber: ['', [
        Validators.required,
        // Validators.minLength(1),
        //Validators.maxLength(16),
        Validators.pattern(AppSettings.CARD_NUMBER_PATTERN)
      ]],
      expiration: ['', [
        Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(5),
        Validators.pattern(AppSettings.CARD_EXPIRATION_PATTERN)
      ]],
      cardSecurityCode: ['', [
        // Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(50),
        // Validators.pattern(AppSettings.NAME_PATTERN)
      ]],



    });

    this.authorizationKey = this.route.snapshot.queryParams['key'];
  }

  validateExpirationDate(event) {
    const input = event.target as HTMLInputElement;
    let count = input.value.length;
    if (count == 5) {
      let expirationDate = this.authorizeForm.controls.expiration.value;
      let r;
      let r1;
      if (expirationDate.includes('/')) {
        r = expirationDate.split('/');
        this.compareExpiration(r[0], r[1]);
      }
      else {
        r1 = expirationDate.toString().substr(0, 2);
        r = expirationDate.toString().substr(-2);
        this.compareExpiration(r1, r);
      }
    }
  }

  compareExpiration(month, year) {
    if (year >= this.year && month >= this.month) {
      this.showExpirationMessage = false;
    } else {
      this.showExpirationMessage = true;
    }
  }

  getCardDetails() {
    this.commonBindingDataService.getCarddetails().subscribe((result) => {
      this.privacyPolicyData = result;
      this.showMessage('Success', result.general[0].message);
      this.authorizeForm.reset();

    }, (reason) => {
      this.showMessage('Error', reason.general[0].message);

    });
  }

  addNow() {
    const data = {
      "cardNumber": (this.authorizeForm.controls.cardNumber.value.trim()).replace(/\s/g, ""),
      "cardExpiryDate": (this.authorizeForm.controls.expiration.value).replace(/\//g, ""),

    };
    this.commonBindingDataService.addUpdateAuthorizeDotNetCard(data, this.authorizationKey).subscribe((result) => {
      this.privacyPolicyData = result;
      this.showMessage('Success', result.general[0].message);
      this.authorizeForm.reset();
      setTimeout(() => {
        this.router.navigate(['authorize-dot-net/success-page']);
      }, 2000);


    }, (reason) => {
      this.showMessage('Error', reason.general[0].message);

    });
  }


  // showMessage(severity, message) {
  //   this.message = [];
  //   this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  // }
  showMessage(severity, message) {
    this.message = [];
    this.messageService.add({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }
}
