import { AppSettings } from './../../app.settings';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from 'app/modules/shared/services/storage.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnInit, OnChanges {
  breadCrumArray: any[];
  headerStr: string;

  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    this.breadCrumArray = [];
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.fillBreaCrumArray();
      }
    });
  }

  ngOnInit() {
    this.fillBreaCrumArray();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillBreaCrumArray();
  }

  fillBreaCrumArray() {
    while (this.breadCrumArray.length > 0) {
      this.breadCrumArray.pop();
    }
    const currentobj = this;
    const url = this.router.url;
    let urlUpated = url;
    if (url.lastIndexOf('?') > 0) {
      // urlUpated = url.substring(0, url.lastIndexOf('?'));
      urlUpated = url.split('?')[0];
    }

    const urlSplitArray = urlUpated.split('/');
    let urlBuilder = '';
    const urlLength = urlSplitArray.length;
    let count = 0;
    urlSplitArray.forEach(element => {
      if (AppSettings.IGNORE_LINK.indexOf(element) === -1) {
        urlBuilder += element + '/';
        let visiableStr = '';
        let relativeUrl;
        if (element.length === 0) {
          visiableStr = 'Dashboard';
          relativeUrl = '#' + this.storageService.getItem('dashboard');
        } else {
          if (element.indexOf('dashboard') < 0) {
            visiableStr = '> ' + currentobj.capitalizeFirstLetter(element);
            relativeUrl = urlBuilder;
            if (relativeUrl.endsWith('/')) {
              relativeUrl = relativeUrl.substring(0, relativeUrl.lastIndexOf('/'));
              relativeUrl = '#' + relativeUrl;
            }
          }
        }
        if (++count === urlLength) {
          if (url.split('?')[1] !== undefined) {
            relativeUrl = relativeUrl + '?' + url.split('?')[1];
          }
        }
        currentobj.breadCrumArray.push({ url: relativeUrl, name: decodeURIComponent(visiableStr) });
      }
    });

    this.headerStr = this.breadCrumArray[this.breadCrumArray.length - 1].name.replace('>', '');
    if (this.headerStr === ' Manage Cities') {
      this.headerStr = ' Manage Cities';
    }
  }

  capitalizeFirstLetter(str) {
    if (str !== undefined && str.length > 0) {
      str = str.replace(/-/g, ' ');
      const words = str.split(' ');
      let strinB = '';
      for (let index = 0; index < words.length; index++) {
        const w = words[index];
        if (w.length > 0) {
          strinB += w.charAt(0).toUpperCase() + w.slice(1);
        } else {
          strinB += w;
        }
        strinB += ' ';
      }
      return strinB.trim();
    }
    return str;
  }
}
