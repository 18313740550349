import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-booking-fare-forcustomer',
  templateUrl: './booking-fare-forcustomer.component.html',
  styleUrls: ['./booking-fare-forcustomer.component.scss']
})
export class BookingFareForcustomerComponent implements OnInit {

  @Output() confirmBookingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() rideType;
  @Input() serviceTypeId;
  @Input() noOfSeats;
  bookingFareModel;
  showCard: any = false;
  tripFare = '';
  distance = '';
  discount = '';
  totalFare = '';
  taxes = '';
  cardNumber = '';
  paclakeNote = '';
  displayPackageNote: Boolean = false;
  displaySaveButton: Boolean = false;
  promoCodeApplied = false;
  packageDuration = '';
  baseFare = '';
  packageDistance = '';
  displayPackageFeilds;
  storeUnit = this.commonBindingDataService.getTenantConfigValue(AppSettings.DISTANCE_UNIT);
  taxi = AppSettings.TAXI;
  rental = AppSettings.RENTAL;
  occasionalRide = AppSettings.OCCASIONAL_RIDE;

  constructor(private commonBindingDataService: CommonBindingDataService) { }

  ngOnInit() {

    // this.cardDetails = this.commonBindingDataService.carddetails.cardNumber;
  }

  calculateFares(bookingFareModel) {
    this.displayPackageFeilds = bookingFareModel.serviceTypeId;

    if (bookingFareModel !== undefined && bookingFareModel !== null) {
      this.bookingFareModel = bookingFareModel;

      if (this.rideType === '3') {
        this.calculateFareAsPerSeat();
      } else {
        this.distance = this.bookingFareModel.distanceString;
        this.discount = this.bookingFareModel.tripPromoDiscountString;
        this.taxes = this.bookingFareModel.taxAmountString;
        this.tripFare = this.bookingFareModel.subTotal;
        this.packageDuration = this.bookingFareModel.vehicleTypeFareInfo.packageDuration;
        this.packageDistance = this.bookingFareModel.vehicleTypeFareInfo.packageDistance;
        this.baseFare = this.bookingFareModel.vehicleTypeFareInfo.baseFare;
        this.totalFare = this.bookingFareModel.estimateFareWithDiscountString;
        if (this.bookingFareModel.packageNote !== null && this.bookingFareModel.packageNote !== undefined) {
          this.paclakeNote = this.bookingFareModel.packageNote;
          this.displayPackageNote = true;
        } else {
          this.displayPackageNote = false;
        }
      }
    }
  }

  getCardNumber(cardNumber, paymentType) {
    this.cardNumber = '';
    if (cardNumber && paymentType === 'CARD') {
      this.cardNumber = cardNumber;
    }

  }

  calculateFareAsPerSeat() {
    const seatFaresList = this.bookingFareModel.estimateDtoList;
    for (let fare = 0; fare < seatFaresList.length; fare++) {
      if (seatFaresList[fare].seatNumber + '' === this.noOfSeats + '') {
        this.distance = seatFaresList[fare].distanceString;
        this.discount = seatFaresList[fare].tripPromoDiscountString;
        this.taxes = seatFaresList[fare].taxAmountString;
        this.tripFare = seatFaresList[fare].subTotalString;
        this.totalFare = seatFaresList[fare].estimateFareWithDiscountString;
      }
    }
  }

  onClickConfirmBooking() {
    this.displaySaveButton = true;
    this.confirmBookingEmitter.emit('confirmed');
    this.displaySaveButton = false;
  }

  onClickBack() {
    this.displaySaveButton = false;
    this.confirmBookingEmitter.emit('back');
  }

}
