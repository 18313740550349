import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { ErrorBindingService } from './../../services/error-binding.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent implements OnInit {
  @Output() onSetPasswordPopClose: EventEmitter<any> = new EventEmitter();
  @Input() otpId;

  passwordError = [{
    message: false
  }];

  errorMessage: Message[] = [];

  setPasswordForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl(),
    newConfirmPassword: new UntypedFormControl()
  });

  constructor(private formBuilder: UntypedFormBuilder,
    private signOutService: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService) { }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]]
    });
  }


  passwordNotMatched() {
    if (this.setPasswordForm.controls.newPassword.value !== this.setPasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.passwordError);
    } else {
      return true;
    }
  }

  requestData() {
    return {
      otpId: this.otpId,
      password: this.setPasswordForm.controls.newPassword.value,
    };
  }

  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      const c = this;
      this.signOutService.setPassword(JSON.stringify(data)).subscribe(results => {
        if (results.general !== null && results.general !== undefined) {
          results.general.forEach(element => {
            this.errorMessage = [];
            this.errorMessage.push({ severity: 'success', summary: 'Success', detail: element.message });
          });
          setTimeout(() => {
            this.setPasswordForm.reset();
            this.onSetPasswordPopClose.emit(false);
          }, 2000);
        }
      }, (error) => {

        if (error.general !== null && error.general !== undefined) {
          error.general.forEach(element => {
            c.errorMessage = [];
            c.errorMessage.push({ severity: 'warn', summary: 'Error', detail: element.message });
          });
        }
      });
    }

  }

  onCancel() {
    this.setPasswordForm.reset();
    this.onSetPasswordPopClose.emit(false);
  }

}
