import { EditableRentalModel } from './../../../corporates/models/editable-rental-model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppSettings } from '../../../shared/app.settings';
import { Message } from 'primeng/api';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-rental-partner-pricing',
  templateUrl: './edit-rental-partner-pricing.component.html'
})
export class EditRentalPartnerPricingComponent implements OnInit {

  @Output() isPackagePopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isRentalPackagePopSave: EventEmitter<any> = new EventEmitter();
  @Input() settingApiError;
  @Input() pricing;

  labelSave;
  labelCancel;
  labelAddPricing;
  corporateId = '';
  packagePricingList: any;
  message: Message[] = [];
  rentalPricingForm = new UntypedFormGroup({
    packageName: new UntypedFormControl(),
    vehicleTypeId: new UntypedFormControl(),
    distance: new UntypedFormControl(),
    minutes: new UntypedFormControl(),
    cancelFee: new UntypedFormControl(),
    addKm: new UntypedFormControl(),
    addHr: new UntypedFormControl(),
    pricingId: new UntypedFormControl()
  });

  initFormControlData = {
    packageName: '',
    minutes: 0,
    distance: 0,
    addKm: 0,
    addHr: 0,
    cancelFee: 0,
    pricingId: ''
  };


  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private translate: TranslateService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.labelAddPricing = this.commonBindingDataService.getLabel('label_add_princing');
    this.rentalPricingForm = this.formBuilder.group({
      vehicleTypeId: ['', [Validators.required]],
      pricingBundle: this.formBuilder.array([
      ])
    });
  }


  initPricingBundle(data?: any) {
    if (data === undefined) {
      data = this.initFormControlData;
    }
    return this.formBuilder.group({
      packageName: [{
        value: data.packageName,
        disabled: true
      }, [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
      minutes: [{
        value: data.minutes,
        disabled: !data.activated
      }, [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      distance: [{
        value: data.distance,
        disabled: !data.activated
      }, [Validators.required, Validators.pattern(AppSettings.DIGIT_PATTERN)]],
      addKm: [{
        value: data.addKm,
        disabled: !data.activated
      }, [Validators.required, Validators.pattern(AppSettings.DIGIT_PATTERN)]],
      addHr: [{
        value: data.addHr,
        disabled: !data.activated
      }, [Validators.required, Validators.pattern(AppSettings.DIGIT_PATTERN)]],
      cancelFee: [{
        value: data.cancelFee,
        disabled: !data.activated
      }, [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      pricingId: [{
        value: data.pricingId,
        disabled: !data.activated
      }]


    });
  }

  editRentalDataBinding(id, corporateId) {
    this.corporateId = corporateId;
    if (id !== null) {
      const url = this.corporateId + '/' +
        this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + AppSettings.SETTING_SERVICE_ID + '/' + id;
      this.commonBindingDataService.getRentalPricingDetails(url).subscribe(results => {
        this.bindData(results);
      }, (error) => {
        this.errorMessage(error);
      });
    }
  }

  addPricingWithData(data) {
    const control = <UntypedFormArray>this.rentalPricingForm.get('pricingBundle');
    control.push(this.initPricingBundle(data));
  }

  removePricing(i: number) {
    const control = <UntypedFormArray>this.rentalPricingForm.get('pricingBundle');
    control.removeAt(i);
  }

  bindData(results) {
    const PackagesOfModel = results;
    this.packagePricingList = [];
    let i = 0;
    for (const value of PackagesOfModel) {
      this.packagePricingList.push(new EditableRentalModel(value));
      this.addPricingWithData(this.packagePricingList[i]);
      i++;
    }
    this.rentalPricingForm.controls.vehicleTypeId.setValue(this.pricing.vehicleType);
  }

  addPackages() {
    const packageList = [];
    for (const pricingPackage of this.rentalPricingForm.value.pricingBundle) {
      packageList.push({
        packageName: pricingPackage.packageName,
        distance: parseInt(pricingPackage.distance, 10),
        minutes: parseInt(pricingPackage.minutes, 10),
        additionalDistanceRate: parseInt(pricingPackage.addKm, 10),
        additionalMinuteRate: parseInt(pricingPackage.addHr, 10),
        cancellationRate: parseInt(pricingPackage.cancelFee, 10),
        id: pricingPackage.pricingId
      });
    }


    const rentalPackageData = {
      packageList: packageList,
    };

    this.commonBindingDataService.updateRentalPackage(rentalPackageData, this.corporateId).subscribe(results => {
      this.successSave(results);
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.settingApiError);
    });
  }

  errorMessage(results) {
    this.message = [];
    this.message.push({ severity: 'error', summary: 'error', detail: results.general[0].message });
  }

  successSave(results) {
    this.message = [];
    this.message.push({ severity: 'success', summary: 'Confirmed', detail: results.general[0].message });
    this.resetRentalForm();
    this.isRentalPackagePopSave.emit(results);
  }

  resetRentalForm() {
    this.rentalPricingForm.reset();
    this.rentalPricingForm = this.formBuilder.group({
      vehicleTypeId: ['', [Validators.required]],
      pricingBundle: this.formBuilder.array([
      ])
    });
  }

  cancel() {
    this.resetRentalForm();
    this.isPackagePopCancel.emit(false);
  }

}
