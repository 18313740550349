export class CityFaresModel {
  vehicleTypeId = '';
  sourceLatitude = 0.0;
  sourceLongitude = 0.0;
  destinationLatitude = 0.0;
  destinationLongitude = 0.0;
  packageId = '';
  promoCode = '';
  pickupDateTime = '';
  constructor(option?: {
    vehicleTypeId: string,
    sourceLatitude: number,
    sourceLongitude: number,
    destinationLatitude: number,
    destinationLongitude: number,
    packageId: string,
    promoCode: string,
    pickupDateTime: string
  }) {
    if (option) {
      this.vehicleTypeId = option.vehicleTypeId;
      this.sourceLatitude = option.sourceLatitude;
      this.sourceLongitude = option.sourceLongitude;
      this.destinationLatitude = option.destinationLatitude;
      this.destinationLongitude = option.destinationLongitude;
      this.packageId = option.packageId;
      this.promoCode = option.promoCode;
      this.pickupDateTime = option.pickupDateTime;
    }
  }
}
