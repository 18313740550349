export class DestModel {
    id = null;
    destinationName = '';
    latitude = null;
    longitude = null;
    destinationAliasName = '';
    parkingEnabled = false;
    parkingAvailability = 0;
    etaFromPreviousStop = 0;
    distanceFromPreviousStop = 0;
    constructor(options?: {
      id: string;
      destinationName: string;
      latitude: string;
      longitude: string;
      destinationAliasName: string;
      parkingEnabled: boolean;
      parkingAvailability: number;
      etaFromPreviousStop: number;
      distanceFromPreviousStop: number;
    }) {
      if (options) {
        this.id = options.id;
        this.destinationName = options.destinationName;
        this.latitude = options.latitude;
        this.longitude = options.longitude;
        this.destinationAliasName = options.destinationAliasName;
        this.parkingEnabled = options.parkingEnabled;
        this.parkingAvailability = options.parkingAvailability;
        this.etaFromPreviousStop = options.etaFromPreviousStop;
        this.distanceFromPreviousStop = options.distanceFromPreviousStop;
      }
    }
  }
  