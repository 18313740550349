import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';


@Injectable()
export class CommonBindingDataService {
  accesses: any;
  manualBookingData: any = {};
  rideType: any;
  carddetails: any = {};
  editButton: any;
  deleteButton: any;
  editRegionButton: any;
  statusButton: any;
  detailsButton: any;
  vehicleAddButton: any;
  vehicleRemovedButton: any;
  cancelButton: any;
  assignButton: any;
  assignDriverBookingButton: any;
  CancelBookingButton: any;
  detailReportButton: any;
  passengerFeedbackButton: any;
  driverFeedbackButton: any;
  addRegionButton: any;
  fareButton: any;
  statusPromoCodeButton: any;
  statusResendButton: any;
  languageButton: any;
  walletButton: any;
  walletPayoutButton: any;
  requestButton: any;
  reportDetailsButton: any;
  addSubscriptionButton: any;
  addAirportZoneButton: any;
  isDriverSubscription = '';
  defaultCurrency = '';
  public redrawSidebar: Subject<any> = new Subject<any>();

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    // this.isDriverSubscription = this.getTenantConfigValue(AppSettings.IS_DRIVER_SUBSCRIPTION);
    // this.defaultCurrency = this.getTenantConfigValue(AppSettings.DEFAULT_CURRENCY);
    this.initialiseButtons();
  }

  getServiceType(): Observable<any> {
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      return of(this.storageService.getItem(AppSettings.SERVICE_TYPE));
    } else {
      return this.restApiService.get('/secure/service-types?clientType=WEB');
    }
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toGMTTime(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time + offset
    if (gmtTime >= 0) {
      return gmtTime;
    } else {
      return ((24 * 3600000) + gmtTime - 1);
    }
  }

  toGMTTime1(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time + offset
    if (gmtTime >= 0) {
      return gmtTime;
    } else {
      return ((24 * 3600000) + gmtTime);
    }
  }



  toCurrentTime(time) {
    const offset = new Date().getTimezoneOffset() * 60000;
    const gmtTime = time - offset
    if (gmtTime >= 24 * 3600000) {
      return (gmtTime - 24 * 3600000);
    } else {
      return gmtTime;
    }
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }


  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }


  getBookLaterMinTime(serviceType, subcode) {
    const extraTime = this.getSpecificTimeout(serviceType, subcode);
    return new Date((new Date).getTime() + extraTime);
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  addLapseTimeInEndDate(endDate) {
    return endDate + AppSettings.twentyThreeFiftyNineLapse;
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }

  getServiceTypeKey(id) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          serviceTypeId = element.serviceType;
        }
      });
    }
    return serviceTypeId;
  }

  getTenantConfigValue(configKey) {
    let tenantConfig: any;
    tenantConfig = this.storageService.getItem(AppSettings.TENANT_CONFIG);
    for (let index = 0; index < tenantConfig.length; index++) {
      if (tenantConfig[index].configKey === configKey) {
        return tenantConfig[index].configValue;
      }
    }
    return null;
  }

 


  getServiceTypeCodeBy(id) {
    let serviceType, code;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          code = element.code;
        }
      });
    }
    return code;
  }

  getServiceTypeByCode(code) {
    let serviceType;
    const serviceTypeByCode = [];
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.code === code) {
          serviceTypeByCode.push(element);
        }
      });
    }
    return serviceTypeByCode;
  }

  getServiceTypeId(key) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.serviceType === key) {
          serviceTypeId = element.id;
        }
      });
    }
    return serviceTypeId;
  }


  removeGivenServiceTypefromList(key, serviceTypeList?) {
    let serviceTypes;
    if (serviceTypeList) {
      serviceTypes = serviceTypeList;
    } else {
      serviceTypes = this.storageService.getItem(AppSettings.SERVICE_TYPE);
    }
    serviceTypes.forEach((item, index) => {
      if (key === item.subCode) {
        serviceTypes.splice(index, 1);
      }
    });
    return serviceTypes;
  }

  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  getServiceTypeOnLogin(): Observable<any> {
    return this.restApiService.get('/secure/service-types?clientType=WEB', 'page-center');
  }

  getDocumentType(): Observable<any> {
    return this.restApiService.get('/secure/driver-document-types', 'page-center');
  }

  getVehicleType(): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types', 'page-center');
  }

  getDurationType(): Observable<any> {
    return this.restApiService.get('/secure/package/duration-types', 'page-center');
  }

  getVehicleTypeByServiceId(serviceId?): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
    // return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
  }

  getVehicleTypeByServiceIdV1(serviceId?): Observable<any> {
    // return this.restApiService.get('/secure/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
    return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
  }

  // for fandsride
  getVehicleTypeByServiceCity(serviceId?,serviceCityId?): Observable<any> {
    // return this.restApiService.get('/secure/vehicle-types?serviceTypeIds=' + serviceId, 'page-center');
    return this.restApiService.get('/secure/v1/vehicle-types?serviceTypeIds=' + serviceId +`&serviceCityId=${serviceCityId}`, 'page-center');
  }

  getVehiclesByCompanyAndVehicleType(vehicleTypeId, companyId?: any, serviceCities?, serviceId?): Observable<any> {
    if (companyId !== null && companyId !== undefined) {
      return this.restApiService.get('/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + companyId, 'page-center');
    } else {
      return this.restApiService.get('/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + '&serviceAreaIds=' + serviceCities + '&serviceTypeIds=' + serviceId, 'page-center');
    }
  }

  getVehicleDocumentType(): Observable<any> {
    return this.restApiService.get('/secure/vehicle-document-types', 'page-center');
  }

  getCountries(): Observable<any> {
    return this.restApiService.get('/secure/countries', 'page-center');
  }

  getCarddetails(): Observable<any> {
    return this.restApiService.get('/secure/authorize-dot-net/card', 'page-center');
  }

  getState(id): Observable<any> {
    return this.restApiService.get(`/secure/countries/${id}/states`, 'page-center');
  }
  getCitybyCountries(code): Observable<any> {
    return this.restApiService.get('/secure/countries/' + code + '/citiesbycountry', 'page-center');
  }

  getCitybyState(code): Observable<any> {
    return this.restApiService.get('/secure/countries/' + code + '/citiesbystate', 'page-center');
  }

  getCitybyZipCode(zipCode, countryCode?: any): Observable<any> {
    return this.restApiService.get('/secure/countries-city?zipCode=' + zipCode + '&countryCode=' + countryCode, 'page-center');
  }

  getDispatcher(data, code): Observable<any> {
    return this.restApiService.get(`/secure/dispatchers/data/search?searchText=${data}&roleCode=${code}`, 'page-center');
  }

  getPackageData(packageId): Observable<any> {
    return this.restApiService.get('/secure/package/' + packageId, 'page-center');
  }

  getVehiclePackageData(packageId): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types/default-package/' + packageId, 'page-center');
  }


  updatePackage(packageId, data): Observable<any> {
    return this.restApiService.put('/secure/vehicle-types/default-package/' + packageId, data, 'page-center');
  }


  commentReply(data, id): Observable<any> {
    return this.restApiService.post(`/secure/booking/trip/${id}/comment-reply`, data, 'page-center');
  }

  postPayment(data): Observable<any> {
    return this.restApiService.post(`/secure/authorize-dot-net/add-edit-card`, data, 'page-center');
  }

  addUpdateAuthorizeDotNetCard(data, authorizationKey): Observable<any> {
    return this.restApiService.postWithCustomHeader(`/secure/authorize-dot-net/add-edit-card`, authorizationKey, data, 'page-center');
  }

  walletRecharge(data, authorizationKey): Observable<any> {
    return this.restApiService.postWithCustomHeader(`/secure/wallet/recharge`, authorizationKey, data, 'page-center');
  }

  pendingPayment(data, authorizationKey): Observable<any> {
    return this.restApiService.postWithCustomHeader(`/secure/authorize-dot-net/make-payment`, authorizationKey, data, 'page-center');
  }

  getAuthorizedCardDetails(authorizationKey): Observable<any> {
    return this.restApiService.getWithCustomHeader(`/secure/authorize-dot-net/card`, authorizationKey, 'page-center');
  }

  getAuthorizedPendingCardDetails(authorizationKey): Observable<any> {
    return this.restApiService.getWithCustomHeader(`/secure/authorize-dot-net/pending-payment`, authorizationKey, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get(url, 'page-center');
  }

  readNotifications(notificationId, readStatus): Observable<any> {
    return this.restApiService.put(`/secure/notification/${notificationId}/status`, readStatus, 'page-center');
  }

  addCorporateBooking(data, serviceType): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/corporate`, data, 'page-center');
  }

  addPackage(data): Observable<any> {
    return this.restApiService.post(`/secure/vehicle-types/default-package`, data, 'page-center');
  }


  addHotelBooking(data, serviceType, hotelId): Observable<any> {
    return this.restApiService.post(`/secure/hotels/${hotelId}/booking/${serviceType}`, data, 'page-center');
  }

  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  getTiming() {
    return this.restApiService.get('/secure/settings/timeouts', 'page-center');
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' +
        serviceType + '?vehicleTypeId=' + vehicleTypeId + '&passengerId=' + passengerId;
      return this.restApiService.get(url);
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get(url);
    }
  }

  getPricingDetails(data) {
    return this.restApiService.get('/secure/packages/' + data, 'page-center');
  }


  setPackages(data, id?, corporateId?): Observable<any> {
    return this.restApiService.put(`/secure/packages/${id}/partners/${corporateId}`, data, 'page-center');
  }

  getRentalPricingDetails(params) {
    return this.restApiService.get('/secure/packages/rental/partners/' + params, 'page-center');
  }

  updateRentalPackage(data, corporateId) {
    return this.restApiService.post(`/secure/packages/rental/partners/${corporateId}`, data, 'page-center');
  }

  updateRentalPackageById(id, data) {
    return this.restApiService.put(`/secure/package/${id}`, data, 'page-center');
  }

  addPricingPackages(data, id?: null) {
    if (id !== undefined && id !== null && id !== '') {
      return this.restApiService.put('/secure/packages/' + id + '/chauffeur', data, 'page-center');
    } else {
      return this.restApiService.post('/secure/packages', data, 'page-center');
    }
  }

  updateCorporatePricingPackages(data, id: null, corporateId) {
    return this.restApiService.put('/secure/packages/' + id + '/partners/' + corporateId, data, 'page-center');

  }

  setRoute(data, corporateId?): Observable<any> {
    return this.restApiService.put(`/secure/packages/routes/destinations/rates/partners/${corporateId}`, data, 'page-center');
  }


  getRoutingDetails(routeId, partnerId) {
    return this.restApiService.get(`/secure/packages/routes/${routeId}/partners/${partnerId}`, 'page-center');
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get(url);
  }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/taxi`, data, 'page-center');
  }

  getHotels(): Observable<any> {
    return this.restApiService.get(`/secure/hotels`, 'page-center');
  }

  assignDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/assign-driver', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/verification-code');
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('/secure/booking/' + id);
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  securityCheck(data, tripid): Observable<any> {
    return this.restApiService.post('/secure/bookings/' + tripid + '/security-check', data);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  rentalEndTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/bookings/' + tripid + '/end-rental-booking', data);
  }

  extendPackage(tripid): Observable<any> {
    return this.restApiService.put('/secure/bookings/' + tripid + '/extend-package');
  }

  getDriverCurrentLocation(driverId): Observable<any> {
    return this.restApiService.get('/secure/drivers/' + driverId + '/location', 'page-center');
  }

  hasAccessPresent(accessCode) {
    const menuList = this.getSelectedMenuAccessesList();
    // console.log(menuList);
    const hasAccess = menuList.find((elm) => {
      return elm.accessCode === accessCode;
    });

    if (hasAccess !== undefined && hasAccess !== null) {
      return true;
    }
    return false;
  }

  getSubmenuListByMenuName(menuName) {
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (menu.name === menuName) {
        return menu.subMenuList;
      }
    }
    return [];
  }

  getSelectedMenuAccessesList(returnObj?) {
    // const menuName = this.storageService.getItem('selectedMenu');
    const location = window.location.href;
    const urlArray = location.split('#');
    const displayUrls = urlArray[urlArray.length - 1];
    const displayUrl = displayUrls.split('?')[0];
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (displayUrl.indexOf(menu.displayUrl) >= 0) {
        // if (menu.accessGroupCode === menuName) {
        if (returnObj) {
          return menu;
        }
        return menu.accesses;
      } else {
        if (menu.subMenuList && menu.subMenuList.length) {
          const subMenuAccess = this.checkSubmenus(menu.subMenuList, displayUrl, returnObj);
          // const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
          if (subMenuAccess !== undefined && subMenuAccess !== null) {
            return subMenuAccess;
          }
        }
      }
    }
    return [];
  }

  checkSubmenus(subMenuList, menuName, returnObj?) {
    if (subMenuList !== undefined && subMenuList !== null) {
      for (let menuIndex = 0; menuIndex < subMenuList.length; menuIndex++) {
        const menu = subMenuList[menuIndex];
        if (menuName.indexOf(menu.displayUrl) >= 0) {
          // if (menu.accessGroupCode === menuName) {
          if (returnObj) {
            return menu;
          }
          return menu.accesses;
        } else {
          if (menu.subMenuList && menu.subMenuList.length) {
            const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
            if (subMenuAccess !== undefined && subMenuAccess !== null) {
              return subMenuAccess;
            }
          }
        }
      }
      return null;
    }
  }

  checkAndSetSelectedMenu(routeUrl) {
    if (!this.accesses) {
      this.accesses = this.storageService.getItem(AppSettings.ACCESS_MENU);
    }
    for (let i = 0; i < this.accesses.length; i++) {
      if (this.compareUrl(this.accesses[i].displayUrl, routeUrl)) {
        this.storageService.setItem('selectedMenu', this.accesses[i].accessGroupCode);
        return true;
      } else {
        if (this.accesses[i].subMenuList.length) {
          for (let j = 0; j < this.accesses[i].subMenuList.length; j++) {
            if (this.compareUrl(this.accesses[i].subMenuList[j].displayUrl, routeUrl)) {
              this.storageService.setItem('selectedMenu', this.accesses[i].subMenuList[j].accessGroupCode);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  compareUrl(srcUrl, routeUrl) {
    return srcUrl ? srcUrl.toLowerCase().indexOf(routeUrl.toLowerCase()) >= 0 : false;
  }

  getCities(): Observable<any> {
    return this.restApiService.get('/secure/service-area/cities');
  }

  getCitiesForBooking(serviceTypeId?, vehicleTypeId?, sourceCityId?): Observable<any> {
    return this.restApiService.get('/secure/service-area/cities?serviceTypeId=' +
      serviceTypeId + '&vehicleTypeId=' + vehicleTypeId + '&sourceCityId=' + sourceCityId);
  }


  getCitiesForOccasional(): Observable<any> {
    return this.restApiService.get('/secure/service-area/tenant-city/list');
  }

  getServiceAreaByCompanyId(companyId): Observable<any> {
    return this.restApiService.get('/secure/companies-service-area/list/' + companyId, 'page-center');
  }

  getServiceAreaByUserId(userId): Observable<any> {
    return this.restApiService.get('/secure/service-area/' + userId + '/city', 'page-center');
  }

  getCompaniesList(serviceArea?): Observable<any> {
    return this.restApiService.get('/secure/companies/list?tenantServiceAreaId=' + serviceArea, 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('/secure/tour/' + tourId);
  }
  getDropDownData(list, key, value) {
    return (list !== null && list !== undefined) ?
      list.map(item => ({ label: item[key], value: item[value] })) : [];
  }

  getUsers(roleId, activated): Observable<any> {
    return this.restApiService.get(`/secure/users-list?roleId=${roleId}&activated=${activated}`, 'page-center');
  }
  getDrivers(vehicleTypeId, tourId?, search?,limit?): Observable<any> {
    const searchData = search ? search : '';
    const maxlimit = limit?limit:10;
    return this.restApiService.get(`/secure/drivers/assign-trip/list/`
      + vehicleTypeId + '?tourId=' + tourId + '&searchText=' + searchData
      + `&limit=${maxlimit}&offset=0`, 'page-center');
  }

  // driverAssigned(): Observable<any> {
  //   return this.restApiService.put(`/secure/tour/assign-driver/${id}/partners/${corporateId}`, data, 'page-center');
  // }
  driverAssigned(tourId, driverId): Observable<any> {
    return this.restApiService.put('/secure/tour/assign-driver/' + tourId + '/' + driverId, 'page-center');
  }

  driverOntheWay(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/tour/driver/ontheway/' + tourId, data, 'page-center');
  }

  driverArrived(tripId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + tripId + '/arrived', data, 'page-center');
  }

  OnTrip(tripId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripId + '/start-trip', data, 'page-center');
  }

  dropTrip(data): Observable<any> {
    return this.restApiService.post('/secure/tour/drop', data, 'page-center');
  }

  rideShareDriverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/driver/on-the-way', 'page-center');
  }

  rideShareDriverArrived(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/driver/arrived', 'page-center');
  }

  rideShareOnTrip(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/start-trip', 'page-center');
  }

  rideShareDropTrip(tourId, data): Observable<any> {
    return this.restApiService.post('/secure/ride-share/' + tourId + '/end-trip', data, 'page-center');
  }

  acknowledgeTrip(tourId, data): Observable<any> {
    return this.restApiService.put('/secure/tour/' + tourId + '/acknowledge', data, 'page-center');
  }

  acknowledgeRideShareTrip(tourId): Observable<any> {
    return this.restApiService.put('/secure/ride-share/' + tourId + '/acknowledge', 'page-center');
  }

  doPayment(data): Observable<any> {
    return this.restApiService.post('/secure/manual-booking/stripe', data, 'page-center');
  }

  getCardDetails(userId): Observable<any> {
    return this.restApiService.get(`/secure/manual-booking/web-stripe/` + userId, 'page-center');
  }

  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/booking/manual/${servicetypeid}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/booking/manual`, data, 'page-center');
  }

  getDateFormat() {
    let dateFormatRecord = this.getTenantConfigValue(AppSettings.DATE_FORMAT).toLowerCase();
    const check = dateFormatRecord.includes('yyyy');
    if (check) {
      dateFormatRecord = dateFormatRecord.replace('yyyy', 'yy');
    }
    return dateFormatRecord;
  }

  getPrivacyPolicy(): Observable<any> {
    return this.restApiService.get('/tenants/privacy-policy', 'page-center');
  }

  cashCollected(tripid, data): Observable<any> {
    // return this.restApiService.put('/secure/booking/trip/' + tripid + '/start-trip', data);
    return this.restApiService.put('/secure/tour/collect-cash/' + tripid, data);
  }

  updateOccasionalStatus(tripid, data): Observable<any> {
    return this.restApiService.put('/secure/occasional-tour-document/' + tripid + '/status', data);
  }
  initialiseButtons() {

    this.fareButton = {
      field: 'FARE',
      class: 'FARE', show: (data) => {
        return `<span class="fa fa-fw doller-sign-bold"
                title="${this.getLabel('label_fares')}">${this.getTenantConfigValue(AppSettings.DEFAULT_CURRENCY)}</span>`;
      },
      title: () => {
        return {
          title: this.getLabel('label_fares'),
          icon: this.defaultCurrency,
        };
      },
    };

    this.addRegionButton = {
      field: 'ADD_REGION',
      class: 'ADD_REGION', show: (data) => {
        return `<span title=${this.getLabel('label_regions')} class="fa fa-fw fa-map-o"></span>`;
      },
      title: () => {
        return {
          title: this.getLabel('label_regions'),
          icon: 'fa fa-fw fa-map-o',
        };
      },
    };

    this.statusButton = {
      field: 'STATUS',
      class: 'STATUS',
      show: (data) => {
        if (data.active || data.activated || data.activationStatus) {
          return `<span title=${this.getLabel('label_active')}
          class="fa fa-fw fa-check-circle grid-btn status activate"></span>`;
        } else {
          return `<span title=${this.getLabel('label_deactivate')}
                class="fa fa-fw fa-ban status grid-btn deactivate"></span>`;
        }
      }
    };

    this.statusPromoCodeButton = {
      field: 'STATUS',
      class: 'STATUS',
      show: (data) => {
        if (data.status !== 'Expired') {
          if (data.active || data.activated) {
            return `<span title=${this.getLabel('label_active')}
          class="fa fa-fw fa-check-circle grid-btn status activate"></span>`;
          } else {
            return `<span title=${this.getLabel('label_deactivate')}
                class="fa fa-fw fa-ban status grid-btn deactivate"></span>`;
          }
        }
      }
    };

    this.requestButton = {
      field: 'REQUEST',
      class: 'REQUEST',
      show: (data) => {
        if (data.requestStatus === '1') {
          return `<span title="${this.getLabel('label_new_request')}"
          class="grid-request-btn new-request fa fa-fw fa-car"></span>`;
        }
        if (data.requestStatus === '2') {
          return `<span title="${this.getLabel('label_attened_request')}"
          class="grid-request-btn attended-request fa fa-fw fa-car"></span>`;
        }
        // if (data.requestStatus == '3') {
        //   return `<span title="${this.getLabel('label_closed_request')}"
        //   class="grid-request-btn closed-request fa fa-fw fa-car"></span>`;
        // }
      }
      /*   show: (data) => {
          if (data.requestStatus == '1') {
            return `<span title=${this.getLabel('label_new_request')}
            class="grid-btn-div pad-30-pc new-request-btn">${this.getLabel('label_new_request')}</span>`;
          }
          if (data.requestStatus == '2') {
            return `<span title=${this.getLabel('label_attened')}
            class="grid-btn-div pad-30-pc attended-btn">${this.getLabel('label_attened')}</span>`;
          }
          if (data.requestStatus == '3') {
            return `<span title=${this.getLabel('label_closed')}
            class="grid-btn-div pad-30-pc closed-btn">${this.getLabel('label_closed')}</span>`;
          }
        } */
    };

    this.walletButton = {
      field: 'WALLET',
      class: 'WALLET',
      show: (data) => {
        if (data) {
          return `<span title=${this.getLabel('label_wallet_update')}
          class="fa fa-fw fa-money grid-btn status"></span>`;

        }
      }
    };

    this.walletPayoutButton = {
      field: 'WALLET-PAYOUT',
      class: 'WALLET-PAYOUT',
      show: (data) => {
        if (data) {
          return `<span title='Wallet Payout' class="fa fa-fw fa-money grid-btn status"></span>`;

        }
      }
    };

    this.assignDriverBookingButton = {
      field: 'ASSIGN-DRIVER-BOOKING',
      class: 'ASSIGN-DRIVER-BOOKING',
      show: (data) => {
        // if (data.appBooking && !data.driverId && data.bookingStatus === AppSettings.NEW_REQUEST_BOOKING_STATUS) {
          if (!data.driverId && data.bookingStatus === AppSettings.NEW_REQUEST_BOOKING_STATUS) {
          return `<span title=${this.getLabel('label_assign_driver')}
          class="fa fa-fw fa-user grid-btn status"></span>`;

        } else {
          return null;
        }
      }
    };
    this.CancelBookingButton = {
      field: 'CANCEL-BOOKING',
      class: 'CANCEL-BOOKING',
      show: (data) => {
        if (data.bookingStatus === AppSettings.DRIVER_ARRIVED_BOOKING_STATUS ||
          data.bookingStatus === AppSettings.ACCEPTED_BOOKING_STATUS ||
          data.bookingStatus === AppSettings.NEW_REQUEST_BOOKING_STATUS ||
          data.bookingStatus === AppSettings.DRIVER_ASSIGNED_BOOKING_STATUS ||
          data.bookingStatus === AppSettings.DRIVER_ON_THE_WAY_BOOKING_STATUS) {
          return `<span title=${this.getLabel('label_cancel')}
          class="fa fa-fw fa-ban grid-btn activate"></span>`;

        } else {
          return null;
        }
      }
    };

    this.statusResendButton = {
      field: 'RESEND',
      class: 'RESEND',
      show: (data) => {
        return `<span title=${this.getLabel('label_resend')}
          class="fa fa-paper-plane status grid-btn activate"></span>`;
      }
    };


    this.editButton = {
      field: 'EDIT',
      class: 'EDIT', show: (data) => {
        return `<span title=${this.getLabel('label_edit')} class="grid-btn fa fa-fw fa-pencil"></span>`;
      },
      title: () => {
        return {
          title: this.getLabel('label_edit'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.vehicleAddButton = {
      field: 'VEHICLE-ADD',
      class: 'VEHICLE-ADD', show: (data) => {
        return `<span title=${this.getLabel('access_vehicle_add')} class="grid-btn fa fa-fw fa-car car-add"></span>`;
      },
      title: () => {
        return {
          title: this.getLabel('access_vehicle_add'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.vehicleRemovedButton = {
      field: 'VEHICLE-REMOVE',
      class: 'VEHICLE-REMOVE', show: (data) => {
        return `<span title=${this.getLabel('label_remove_vehicle')} class="grid-btn fa fa-fw fa-car car-remove " ></span>`;
      },
      title: () => {
        return {
          title: this.getLabel('label_remove_vehicle'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.editRegionButton = {
      field: 'EDIT',
      class: 'EDIT', show: (data) => {
        if (data.activated) {
          return `<span title=${this.getLabel('label_edit')} class="grid-btn fa fa-fw fa-pencil "></span>`;
        }
      },
      title: () => {
        return {
          title: this.getLabel('label_edit'),
          icon: 'fa fa-pencil',
        };
      },
    };

    this.deleteButton = {
      field: 'DELETE',
      class: 'DELETE', show: (data) => {
        if (data.activated) {
          return `<span  title=${this.getLabel('label_delete')} class="grid-btn fa fa-fw fa-trash status deactivate"></span>`;
        }
      },
      title: () => {
        return {
          title: this.getLabel('label_delete'),
          icon: 'fa fa-trash',
        };
      },
    };

    this.detailsButton = {
      field: 'DETAILS',
      class: 'DETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class="grid-btn view fa fa-fw fa-eye"></span>`;
      }
    };

    this.languageButton = {
      field: 'LANGUAGE',
      class: 'LANGUAGE',
      title: () => {
        return {
          title: this.getLabel('label_change_language'),
          icon: 'fa fa-globe',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_change_language')} class="grid-btn fa fa-fw fa-globe"></span>`;
      }
    };
    this.reportDetailsButton = {
      field: 'REPORT-DETAILS',
      class: 'REPORT-DETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class="grid-btn fa fa-hand-pointer-o  activate rightSpace"></span>`;
      }
    };

    /* this.cancelButton = {
      field: 'CANCEL',
      class: 'CANCEL',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-fw fa-ban',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class="grid-btn view fa fa-fw fa-eye"></span>`;
      }
    }; */

    this.assignButton = {
      field: 'ASSIGN',
      class: 'ASSIGN',
      title: (data) => {
        if (data.activated) {
          return {
            title: this.getLabel('label_assign'),
            icon: 'fa fa-user-circle-o',
          };
        } else {
          return null;
        }
      },
      show: (data) => {
        if (data.activated) {
          return `<span title=${this.getLabel('label_assign')} class="grid-btn fa fa-fw fa-user-circle-o"></span>`;
        } else {
          return null;
        }
      }
    };

    this.detailReportButton = {
      field: 'REPORTDETAILS',
      class: 'REPORTDETAILS',
      title: () => {
        return {
          title: this.getLabel('label_details'),
          icon: 'fa fa-eye',
        };
      },
      show: () => {
        return `<span title=${this.getLabel('label_details')} class="fa fa-hand-pointer-o fa-lg status deactivate"></span>`;
      }
    };


    this.passengerFeedbackButton = {
      field: 'PASSENGER-FEEDBACK',
      class: 'PASSENGER-FEEDBACK',
      title: () => {
        return {
          title: this.getLabel('label_report__feedback'),
          icon: 'fa fa-comments-o',
        };
      },
      show: (data) => {
        if (data.passengerComment) {
          return `<span title=${this.getLabel('label_report__feedback')} class="fa fa-comments-o fa-lg status activate"></span>`;
        }
      }
    };

    this.driverFeedbackButton = {
      field: 'DRIVER-FEEDBACK',
      class: 'DRIVER-FEEDBACK',
      title: () => {
        return {
          title: this.getLabel('label_report__feedback'),
          icon: 'fa fa-comments-o',
        };
      },
      show: (data) => {
        if (data.driverComment) {
          return `<span title=${this.getLabel('label_report__feedback')} class="fa fa-comments-o fa-lg status activate"></span>`;
        }
      }
    };

    this.addSubscriptionButton = {
      field: 'ADD-SUBSCRIPTION',
      class: 'ADD-SUBSCRIPTION',
      show: (data) => {
        if (data.vehicleTypeId && this.isDriverSubscription) {
          return `<span title="Driver Subscription"
          class="fa fa-fw fa-money grid-btn status"></span>`;

        } else {
          return null;
        }
      }
    };

    this.addAirportZoneButton = {
      field: 'ADD-AIRPORT-ZONE',
      class: 'ADD-AIRPORT-ZONE',
      show: (data) => {
        return `<span title="Airport Zone"
          class="fa fa-fw fa-plane"></span>`;
      }
    };
  }

  checkPaymentShowHide() {
    let shoPayment = true; 
    let configArray = [];
    configArray = this.getTenantConfigValue(AppSettings.HIDE_PAYMENT_DETAILS).split(',');
    if(configArray.indexOf('ADMIN')>0){
      shoPayment = false
    }
    return shoPayment;
  }

  getServiceAvailability(): Observable<any> {
    return this.restApiService.get('/service-unavailability/message/data');
  }
}
